import { Injectable } from '@angular/core';
import { OverallSession } from '@models/overall_session.model';
import { DB_CONFIG } from '../app.firebase.config';
import { map } from 'rxjs/operators';
import { Utilities } from '@common/utilities';
import * as _ from 'underscore';
import * as moment from 'moment';
import { AppEnum } from '@enums/AppEnum';
import { AdminService } from './admin.service';
import { IApps } from '@interfaceapps.interface';
import { AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import { Platform } from '@ionic/angular';
import { APPS } from '@app/app.constants.config';

@Injectable({
  providedIn: 'root'
})
export class OverallSessionService extends Utilities {
  private overallSessionCollection: AngularFirestoreCollection<OverallSession>;
  private sessions;

  private suggestedUse = {
    yahootie: 3,
    braingame: 5,
    assessment: 12,
    clubhouse: 1,
    happyplace: 5,
    settings: 0
  };

  private goalPercentage = {
    yahootie: 67,
    braingame: 80,
    assessment: 0,
    clubhouse: 14,
    happyplace: 80,
    settings: 0
  };
  user;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private platform: Platform,
    private adminService: AdminService
    ) { super();
    this.getUser(); }

  getUser() {
    this.authService.userSubject.subscribe(user => {
      if(!user){return;}
      this.user = user;
      this.switchUser(user.id);
    })
  }

   /** Save overall session to the DB @param overall_session overall_session object */
  async saveOverallSession(overallSession: OverallSession) {
    this.overallSessionCollection = this.afs.collection(DB_CONFIG.overall_session_endpoint);
    try {
      const res = await this.overallSessionCollection.add(overallSession);
      return res.id;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Saves the overall session and device type information to the database.
   */
   saveOverallSessionApp(appName: AppEnum): void {
    const overallSession = {} as OverallSession;

    overallSession.user_id = this.user.id;
    overallSession.subscriber_id = this.user.subscriber_id;
    overallSession.app_id = appName;
    overallSession.start_date = moment().toDate();
    overallSession.end_date = null;

    const deviceType = this.platform.platforms();

    if (deviceType[0]) {
      if (_.contains(deviceType, 'tablet')) {
        overallSession.device_type = 'tablet';
      } else if (_.contains(deviceType, 'mobile')) {
        overallSession.device_type = 'mobile';
      } else if (_.contains(deviceType, 'desktop')) {
        overallSession.device_type = 'desktop';
      } else {
        overallSession.device_type = 'none';
      }
    } else {
      overallSession.device_type = 'none';
    }

    // save profile to DB
    this.saveOverallSession(overallSession);
  }

  /**
   * Get User Session By Date
   * @param userId The user Id
   * @param startDate The Start Date
   * @param endDate The End Date
   * @param apps Apps
   */
  getUserSessionByDateNoFormat(userId: string, startDate: Date, endDate: Date, apps: any, userRole: string) {
    this.overallSessionCollection = this.afs.collection<OverallSession>(
      DB_CONFIG.overall_session_endpoint,
      ref => ref.orderBy('start_date').startAt(startDate)
      .endAt(endDate).where('user_id', '==', userId)
    );
    return this.overallSessionCollection.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          const sessionCount = this.formatSessionData2(data, apps, userRole);
          return sessionCount;
        })
      );
  }

  /**
   * Get User Session By Date
   * @param userId The user Id
   * @param startDate The Start Date
   * @param endDate The End Date
   * @param apps Apps
   */
  getUserSessionByDate(userId: string, startDate: Date, endDate: Date, apps: any, userRole: string) {
    this.overallSessionCollection = this.afs.collection<OverallSession>(
      DB_CONFIG.overall_session_endpoint,
      ref => ref.orderBy('start_date').startAt(startDate)
      .endAt(endDate).where('user_id', '==', userId)
    );

    const daysBack = moment(endDate).diff(startDate, 'd');
    return this.overallSessionCollection.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          const sessionCount = this.formatSessionData(data, apps, userRole, daysBack);
          return sessionCount;
        })
      );
  }

  /**
   * Format Session Data
   * @param data Data Filter
   * @param apps Apss
   */
  formatSessionData(data: any, apps: any, userRole: string, daysBack?: number) {
    const countByGamePlay = _.countBy(data, (item) => {
      return item.app_id;
    });
    
    const groupData = _.groupBy(_.sortBy(data, 'start_date').reverse(), 'app_id');
    const countByGamePlayKeys = Object.keys(countByGamePlay);
    const countByGamePlayValues = Object.values(countByGamePlay);
    const groupDataKeys = Object.keys(groupData);
    const groupDataValues = Object.values(groupData);

    _.each(apps, (app: IApps) => {

      // the user has permission to access the app
      // then add a property to the object and store
      const hasPermission = !app.active ? false : _.contains(app.permissions, userRole);
      const lastPlayedDateIndex = groupDataKeys.indexOf(app.name);
      app.hasPermission = hasPermission;
      
      // Remove duplicate games played on the same day
      const uniqueList = _.uniq(groupDataValues[lastPlayedDateIndex], (item, key) => {
        return moment(this.convertTimestampToDate(item.start_date)).format('LL');
      });
      if (uniqueList.length > 0) {
        app.numberOfSessionsPlayed = uniqueList.length;
        const givenTime = moment(this.convertTimestampToDate(uniqueList[0].start_date))
        const timeDiff = moment().add(1,'d').startOf('date').diff(givenTime, 'days')
        app.daysSinceLastUse = timeDiff;
      } else {
        app.daysSinceLastUse = 7;
        app.numberOfSessionsPlayed = 0;
      }
      
      // Only Perform Compliance Calculations for Apps with Permission
      if (hasPermission) {
        // Calculate Compliance
        this.oldcalculateCompliance(app, daysBack);
     } else {
        app.compliancePercentage = 0;
      }
      
    });

    // Filter Only Game Apps. Remove Settings
    const gameApps = apps.filter((app: { name: AppEnum; }) => {
      return app.name !== AppEnum.Settings;
    });

    const sortedList = _.sortBy(gameApps, 'compliancePercentage'); // Sort the find lowest Compliance
    const appsData = {
      apps,
      recommendedGame: sortedList[0].name
    };

    return appsData;
  }

   /**
   * Format Session Data
   * @param data Data Filter
   * @param apps Apss
   */
  formatSessionData2(data: any, apps: any, userRole: string) {
    const groupData = _.groupBy(_.sortBy(data, 'start_date').reverse(), 'app_id');
    const groupDataKeys = Object.keys(groupData);
    const groupDataValues = Object.values(groupData);
    const appies = [];

    _.each(apps, (app: IApps) => {

      // the user has permission to access the app
      // then add a property to the object and store
      // const hasPermission = !app.active ? false : _.contains(app.permissions, userRole);
      const lastPlayedDateIndex = groupDataKeys.indexOf(app.name);
      if (lastPlayedDateIndex < 0) {
        console.log('no data');
        return;
      }
      // app.hasPermission = hasPermission;
      
      // Remove duplicate games played on the same day
      const uniqueList = _.uniq(groupDataValues[lastPlayedDateIndex], (item, key) => {
        return moment(this.convertTimestampToDate(item.start_date)).format('LL');
      });
      const finalList = [];
      for (let item of uniqueList){
        const finalItem = {
          name: item.app_id,
          date: moment(this.convertTimestampToDate(item.start_date)).format('LL'),
          imageUrl: app.imageUrl,
        };
        finalList.push(finalItem);
      }
      appies.push(finalList);
    });

    return appies;
  }

  /**
   * Calculate Compliance
   * @param app App Data
   */
   public oldcalculateCompliance(app: IApps, daysBack?: number) {
    if(!daysBack){
      daysBack = 7;
    }
    daysBack++;

    const appName = app.name.replace(/\s+/g, '').toLowerCase();
    const settings = AppEnum.Settings;
    
    if (appName === settings.toLowerCase() || !app.active) {
      app.suggestedUse = 0;
      app.goalPercentage = 0;
      app.compliancePercentage = 0;
      app.statusClass = '';
      app.suggestedUse  = this.suggestedUse[appName]; // Get Suggested Use based on App
    } else {
      app.suggestedUse  = this.suggestedUse[appName]; // Get Suggested Use based on App
      app.goalPercentage = this.goalPercentage[appName]; // Get Goal Percentage based on App

      // No Status Class For Assessment
      if (app.name === AppEnum.Assessment) {
        app.statusClass = '';
      } else {
        const suggested = (app.suggestedUse / 7) * daysBack;
        // Perform Calculation using formula
        // (1 - ((suggestedUse – (number of days in the past 7 days in which they used the training path) / suggestedUse)) * 100

        // eg. (1 - ((5 – 4) / 5)) * 100 => (1-(1/5)) * 100 => (1-.2)*100 => .8*100 => 80%
        
        app.compliancePercentage = (1 - ((suggested - app.numberOfSessionsPlayed) / suggested )) * 100;
        app.statusClass = this.getComplicanceColor(app.compliancePercentage);
        if(app.compliancePercentage > 100) {
          app.compliancePercentage = 100;
        }
      }

    }

    return app;
  }

  /**
   * Get Compliance Color Based on Compliance Percentage and Formula
   * @param compliancePercentage The Compliance Percentage
   */
   private getComplicanceColor(compliancePercentage: number) {
    let color = '';

    if (compliancePercentage > 0 ) {
        color = 'good';
    } else if ( (compliancePercentage < 0) && (compliancePercentage > -40) ) {
        color = 'warn';
    } else {
      color = 'bad';
    }
    return color;
  }

  async switchUser(userId) {
    const start = moment().day(0).subtract(6, 'w').startOf('d').toDate();
    const end = moment().endOf('d').toDate();
    this.getSessions(start, end, userId);
    return true;
  }

  getSessions(start, end, userId){
    if(!userId){throw new Error('no user')}
    this.adminService.getEntriesByDate(DB_CONFIG.overall_session_endpoint, start, end, 'user_id', userId, 'start_date').subscribe(sessions => {
      this.sessions = sessions;
      this.loadBaseCompliance();
    });
  }

  async getCompliance(start, end) {
    const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    const loadTimer = 500; const loadTries = 5; let tries = 0;
    while(!this.sessions) {
      await wait(loadTimer).then( () => {
        console.log('session load try #' + tries);
        tries++;
        if (tries > loadTries) {
          throw new Error('Sessions not loading');
        }
      });
    }
    const sess = this.sessions.filter(session => {return this.isBetweenDates(session.start_date, start, end)});
    const daysBack = Math.ceil(end.diff(start, 'd', true))
    return this.calculateCompliance(sess, daysBack);
  }


  isBetweenDates(obj, startDate, endDate) {
    const testTime = this.firebaseDateToDate(obj);
    var objectStart = moment(testTime);
    var start = moment(startDate);
    var end = moment(endDate);
    return (objectStart.isSameOrAfter(start) && objectStart.isSameOrBefore(end));
  }

  /**
   * Calculate Compliance
   * @param app App Data
   */
  async calculateCompliance(sessions, daysBack) {
    if(!daysBack){daysBack = 1}
    const grouped_sessions = _.groupBy(sessions, item => {return item.app_id});
    const apps = APPS;
    const promises = apps.map(appie => {
      const app = Object.assign(new Object, appie);
      if(app.name === 'Assessment'){return app;}
      const appName = app.name.replace(/\s+/g, '').toLowerCase();
      const suggested = (this.suggestedUse[appName]/7)*daysBack; // calculates suggested use based on how many days we are calculating from
      let game_sessions = grouped_sessions[app.name];
      // Remove duplicate games played on the same day
      game_sessions = _.uniq(game_sessions, (item, key) => {
        return moment(this.convertTimestampToDate(item.start_date)).format('LL');
      });
      if(!game_sessions){game_sessions = []}
      app['numberOfSessionsPlayed'] = game_sessions.length;
      app['goalPercentage'] = this.goalPercentage[appName];
      app['compliancePercentage'] = (1 - ((suggested - game_sessions.length) / suggested )) * 100;
      if(app['compliancePercentage'] > 100){app['compliancePercentage'] = 100;}
      if(game_sessions.length){
        const givenTime = moment(this.convertTimestampToDate(game_sessions[game_sessions.length - 1].start_date))
        const timeDiff = moment().add(1,'d').startOf('date').diff(givenTime, 'days')
        app['daysSinceLastUse'] = timeDiff;
      } else {

      }
      return app;
    });
    await Promise.all(promises);
    return promises;
  }

  async complianceFromApps(apps) {
    let compliance = 0;
    const promises = apps.map(app => {
      if(app.name === 'Assessment'){return}
      compliance += app['compliancePercentage'];
    });
    await Promise.all(promises);
    return compliance/(apps.length - 1);
  }

  async assignCategoryFromApps(apps){
    const promises = apps.map(async(app) => {
      if (app.name !== AppEnum.Assessment) {
        // if the app hasn't met compliance percentage, and the app hasn't been played today, add it today's activities
        if (app.compliancePercentage < app.goalPercentage && app.daysSinceLastUse >= 1 ) {
          app['category'] = 'today';
        // if the app has been played today, add to more activities
        } else if(app.daysSinceLastUse === 0) {
          app['category'] = 'today-compliant';
        }else {
          app['category'] = 'more';
        }            
      }
    });
    await Promise.all(promises);
    return apps;
  }


  baseCompliances
  async loadBaseCompliance() {
    const start = moment().subtract(1, 'weeks').startOf('day');
    const end   = moment().endOf('day');
    const test  = await this.getCompliance(start, end);
    await this.assignCategoryFromApps(test);
    this.baseCompliances = test;
    return;
  }

  getBaseComplianceByApp(AppName){
    if(!this.baseCompliances){ throw new Error('base compliance not loaded')}
    return this.baseCompliances.find(comp => {return comp.name === AppName})
  }
}
