import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { map, concatMap, catchError, take } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { IUser } from '@interface/user.interface';
import { TeamPlayer } from '@interface/team-player.interface';
import { DB_CONFIG } from '../app.firebase.config';
import { Observable, of, BehaviorSubject, throwError, ReplaySubject } from 'rxjs';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { User } from '@models/user.interface';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { RoleEnum } from '@enums/RoleEnum';
import {v4 as uuid} from 'uuid';

@Injectable()
export class AuthenticationService {
  private users: AngularFirestoreCollection<IUser>;
  private profileUserCollection: AngularFirestoreCollection<IUser>;

  eventId: string;
  teamId: string;
  teamName: string;
  role: string;
  userEmail: string;

  private emailString = '@be.com';
  userSubject = new ReplaySubject<IUser>(1);
  user;
  teamPlayerSubject = new BehaviorSubject<TeamPlayer>(null);

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private httpClient: HttpClient,
    public ngZone: NgZone,
    private ngFunctions: AngularFireFunctions
  ) { this.getAuthUser2() }

  /**
   * Signs the user out.
   */
  signOut(): void {
    this.afAuth.signOut();

    if (!(this.router.url === '/login')) {
      this.router.navigate(['/login']);
    }
  }
  
  /**
   * Authenticates the user.
   * @param user The user.
   * @returns The authenticated user.
   */
  async authenticateUser(user: IUser) {
    // concat string to end of name for email formatting
    let username = user.email;
    // no email add our email string and try that
    if(user.email.indexOf('@') === -1){
      username = user.email + this.emailString;
    }
    
    await this.afAuth.signInWithEmailAndPassword(username, user.password);
    const test = this.userSubject.subscribe(user => {
      // this.adminService.user = user;   
      // console.log(user);
    });

    return this.getAuthUser().pipe(first(authUser => {
      if (authUser && authUser.length > 0) {
        this.userSubject.next(authUser[0]);
        // this.adminService.user = authUser[0];   
        return authUser;
      }

      return authUser;
    })).toPromise();
  }


  /**
   * Gets the user's authentication status.
   * @returns The Firebase User.
   */
  private getUserAuthentication() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  /**
   * Gets the user from the user subject that is set on log in.
   * @returns A promise that returns the current logged in user or brained2 as a default.
   */
  getUser(): Promise<User> {
    return new Promise<User>((res,rej) => {
      this.userSubject.pipe(take(1)).subscribe(user => {
        if(user) {
          res(user);
        } else {
          throw new Error('No User Found')
        }
      })
    })
  }

  async getUserByUID(uid): Promise<any> {
    const user = await this.afs.collection<IUser>(DB_CONFIG.user_endpoint).doc(uid).ref.get();
    this.userSubject.next(user.data());
    return user.data();
  }

  getAuthUser2(){
    // Setting logged in user in localstorage else null
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        this.user = await this.getUserByUID(user.uid);
        // this.userSubject.next(this.user);
        return;
      }
    });
  }

  /**
   * Gets the info for the currently logged in user.
   * @returns The user's data.
   */
  getAuthUser(): Observable<any> {
    return this.afAuth.authState.pipe(concatMap(auth => {
      if (!auth) {
        return of(null);
      }

      this.users = this.afs.collection<IUser>(
        DB_CONFIG.user_endpoint,
        ref => ref.where('uid', '==', auth.uid)
      );

      return this.users.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as IUser;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      );
    }));
  }

  /**
   * Checks if the user is logged in.
   * @returns Whether the user is logged in.
   */
  async isLoggedIn() {
    const user = await this.getUserAuthentication();

    if (user) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Creates an account for the user and stores the data in the Firestore.
   * If the user is already registered, it insteads updates the user in the user table.
   * @param user The user information.
   * @returns The user.
   */
  async registerUser(user: IUser, update?: boolean) {
    if(update) {
      this.updateUser(user);
      return;
    }
    
    this.users = this.afs.collection(DB_CONFIG.user_endpoint);
    if(!user.email) {
      user.email = user.username + this.emailString;
    }
    

    try {
      const res = await this.afAuth.createUserWithEmailAndPassword(user.email, user.password);
      user.uid = res.user.uid;
      delete user.password;
      await this.saveEntryById(user, DB_CONFIG.user_endpoint);
      this.addCustomClaim(user);
      return true;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  /**
   * Converts the username to an email by concatenation.
   * @param user The user's information.
   */
  convertUsernameToEmail(user: IUser): IUser {
    if (user.email === '') {
      user.email = user.username + this.emailString;
    }

    return user;
  }

  private addCustomClaim(user: User) {
    // assigns custom claims to users, can only be done by an admin currently. 12/9/21
    switch(user.role) {
      case RoleEnum.Admin: {
        let addAdmin = this.ngFunctions.httpsCallable('addAdminToken');
        addAdmin(user).pipe(take(1)).subscribe(result => {
          return result;
        });
        break;
      }
      case RoleEnum.Subscriber: {
        let addSub = this.ngFunctions.httpsCallable('addSubscriberToken');
        addSub(user).pipe(take(1)).subscribe(result => {
          return result;
        });
        break;
      }
      case RoleEnum.Staff: {
        let addStaff = this.ngFunctions.httpsCallable('addStaffToken');
        addStaff(user).pipe(take(1)).subscribe(result => {
          return result;
        });
        break;
      }
    }
  }

  /**
   * Tests the password of the user.
   * @param user The user's information.
   * @returns An object from the POST method.
   */
  passwordTest(user: IUser): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'text/plain',
      })
    };
    return this.httpClient.post('https://us-central1-brain-evolved.cloudfunctions.net/obsChangePass', user.uid, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  async passwordReset(email) {
    return this.afAuth.sendPasswordResetEmail(email)
    .then(() => {
      return true
    }).catch(() => {
      return false
    });
  }

  adminTest(user: any) {
    let addAdmin = this.ngFunctions.httpsCallable('addAdmin');
    return addAdmin(user).subscribe(result => {
      console.log(result);
    });
  }

  verifyToken() {
    this.afAuth.idTokenResult.subscribe(data => {
      console.log(data);
    })
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else if (error.status === 200) {
      console.log('success');
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  // update user info, if password is changed we need to del from auth table and update the user entry
  updateUser(user: IUser) {
    return this.saveEntryById(user, DB_CONFIG.user_endpoint).then(x => {
      this.addCustomClaim(user);
      return x;
    });
  }

   /**
   * saves entry to our database NEWER(02/24/2022)
   * @param entry the entry to save to the database
   * @param table the table to save to. use 'DB_CONFIG' to find endpoints. under src/app/firebase.config
   * @returns a promise that returns id on success. otherwise throws an error
   */
   async saveEntryById(entry: any, table: string) {
    if(!entry) {
      return;
    }
   if(!entry.id) {
     entry.id = uuid();
   }

   if(typeof entry === 'object') {
     const keys = Object.keys(entry);
     keys.forEach(key => {
       const entryField = entry[key];
       if(Array.isArray(entryField)) {
         entryField.forEach((ent,index) => {
           if(typeof ent !== 'object') {return;}
           let objCopy = new Object();
           if(!ent.created_date) {
             ent.created_date = new Date().toJSON();
           }
           if(!ent.modified_date) {
             ent.modified_date = new Date().toJSON();
           }
           Object.assign(objCopy, ent);
           entryField[index] = objCopy;
         });
       } else if(typeof entryField === 'object') {
         let objCopy3 = new Object();
         Object.assign(objCopy3, entryField);
         entry[key] = objCopy3;
       }
     })
   }

   let objCopy2 = new Object();
   Object.assign(objCopy2, entry);
   entry = objCopy2;

   if(!entry.created_date || typeof(entry.created_date) === 'object') {
     // entry.created_date = new Date();
     entry.created_date = new Date().toJSON();
   }
   entry.modified_date = new Date().toJSON();
   await this.afs.doc<any>(`${table}/${entry.id}`).set(entry).then(result => {
     console.log('saving', entry);
   }).catch(err => {
     return err;
   });
   return entry.id;
 }
}
