import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { ModalController } from '@ionic/angular';
import { AddOutsideTrainingComponent } from '../modal/outside-training/add/add-outside-training.component';
import { RemoveOutsideTrainingComponent } from '../modal/outside-training/remove/remove-outside-training.component';
import { OutsideTrainingEnum } from '@enums/OutsideTrainingEnum';
import { OutsideTrainingFlyoutComponent } from '../modal/flyout/outside-training/outside-training-flyout.component';
import { AdminService } from '@services/admin.service';
import { DB_CONFIG } from '@app/app.firebase.config';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@models/user.interface';
import { take } from 'rxjs/operators';
import { OutsideTraining } from '@interface/outside_training.interface';
import moment from 'moment';
import { OutsideTrainingService } from '@services/outside-training.service';

@Component({
  selector: 'app-outside-training',
  templateUrl: './outside-training.component.html',
  styleUrls: ['./outside-training.component.scss'],
})
export class OutsideTrainingComponent extends Utilities implements OnInit, OnChanges {

  @Input() selectedDay: number = 0;

  categorySettings = this.trainingService.categorySettings;
  outsideTrainings = [] as OutsideTraining[];
  user: User = {} as User;
  trainingCount: number = 0;
  trainingText: string = 'Trainings';

  constructor(
    public modalController: ModalController,
    private adminService: AdminService,
    private authService: AuthenticationService,
    private trainingService: OutsideTrainingService
  ) { super(); }

  ngOnInit() {
    this.getUser();
  }

  ngOnChanges() {
    if (this.user) {
      this.getOutsideTrainings();
    }
  }

  /**
   * Gets the user from the AuthenticationService then starts searching for outside trainings
   */
  getUser(): void {
    this.authService.userSubject.subscribe(user => {
      if (user) {
        this.user = user;
      }
      this.getOutsideTrainings();
    })
  }

  /**
   * Gets the outside training from the database then loads the circle properly.
   */
  getOutsideTrainings(): void {
    const tempOutsideTrainings = [];

    this.adminService.getEntries(DB_CONFIG.outside_training_endpoint, 'created_date', 'user_id', this.user.id)
      .pipe(take(1)).subscribe(trainings => {
        trainings.forEach(training => {
          if (moment.unix(training['created_date'].seconds).format('MMM DD, YYYY') == moment().subtract(this.selectedDay, 'days').format('MMM DD, YYYY')) {
            tempOutsideTrainings.push(training);
          }
        });

        tempOutsideTrainings.length == 0 ? this.outsideTrainings = [] : this.outsideTrainings = tempOutsideTrainings;
    
        this.updateValues();
      });
  }

  /**
   * Sets how many outside trainings fall into each category.
   */
  setCategoryCounts(): void {
    this.outsideTrainings.forEach((outsideTraining) => {
      outsideTraining.categories.forEach((category, i) => {
        if (category.selected) {
          this.categorySettings[i].count++;
        }
      })
    });
  }
  
  /**
   * Sets the percentages of the categories for the app percentage circle components.
   */
  setCircles(): void {
    this.categorySettings.forEach(category => {
      if (this.trainingCount == 0) {
        category.percent = 0;
      } else {
        category.percent = category.count / this.trainingCount * 100;
      }
    });
  }

  /**
   * Sets how many outside trainings there are
   */
  setTrainingCount(): void {
    this.trainingCount = this.outsideTrainings.length;
  }

  /**
   * Resets the precentage and count for each of the category setting 
   * so setCategoryCounts() and setCircles() can function properly.
   */
  resetValues(): void {
    this.categorySettings.forEach(category => {
      category.percent = 0;
      category.count = 0;
    });
  }

  /**
   * Sets the "Outside Training(s)" text to properly reflect the singular 
   * or plural amount of outside trainings.
   */
  setTrainingText(): void {
    this.trainingText = this.trainingCount === 1 ? 'Training' : 'Trainings';
  }

  /**
   * Clears the category counts and percentages then sets the category 
   * settings and the training text.
   */
  updateValues(): void {
    this.resetValues();
    this.setTrainingCount();
    this.setCategoryCounts();
    this.setCircles();
    this.setTrainingText();
  }

  /**
   * Presents the Add Outside Training modal, when the modal is closed the 
   * category settings are updated
   */
  async showAddTraining(): Promise<void> {
    await this.presentModal(AddOutsideTrainingComponent, 'outside-training-modal', {
      outsideTrainings: this.outsideTrainings,
      selectedDay: this.selectedDay
    });
    await this.getModal().onDidDismiss();
    this.updateValues();
  }

  /**
   * Presents the Remove Outside Training modal, when the modal is closed the 
   * category settings are updated
   */
  async showRemoveTraining(): Promise<void> {
    await this.presentModal(RemoveOutsideTrainingComponent, 'outside-training-modal', {
      outsideTrainings: this.outsideTrainings
    });
    await this.getModal().onDidDismiss();
    this.updateValues();
  }

  /**
   * Presents the Outside Training flyout
   */
  showTrainingFlyout(): void {
    this.presentModal(OutsideTrainingFlyoutComponent, 'flyout-modal', {
      trainingCount: this.trainingCount,
      outsideTrainings: this.outsideTrainings,
      trainingText: this.trainingText
    });
  }
}
