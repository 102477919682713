import { Injectable } from '@angular/core';
import { OutsideTrainingEnum } from '@enums/OutsideTrainingEnum';

@Injectable({
  providedIn: 'root'
})
export class OutsideTrainingService {

  categorySettings = [
    {
      id: OutsideTrainingEnum.Mental,
      icon: 'extension-puzzle',
      iconColor: '#5b9bd5',
      background: '#bdd7ee',
      count: 0,
      percent: 0,
      list: []
    },
    {
      id: OutsideTrainingEnum.Social,
      icon: 'chatbubbles',
      iconColor: '#ffc000',
      background: '#ffe699',
      count: 0,
      percent: 0,
      list: []
    },
    {
      id: OutsideTrainingEnum.Stress,
      icon: 'rose',
      iconColor: '#ed7d31',
      background: '#f8cbad',
      count: 0,
      percent: 0,
      list: []
    },
    {
      id: OutsideTrainingEnum.Physical,
      icon: 'bicycle',
      iconColor: '#44546a',
      background: '#adb9ca',
      count: 0,
      percent: 0,
      list: []
    }
  ];
  
  constructor() { }
}
