import { EventEmitter, Injectable } from '@angular/core';
import { Utilities } from '@common/utilities';
import { RoleEnum } from '@enums/RoleEnum';
import { IApps } from '@interfaceapps.interface';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { OverallSession } from '@models/overall_session.model';
import { AuthenticationService } from '@services/authentication.service';
import { OverallSessionService } from '@services/overall-session.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import * as _ from 'underscore';
import * as moment from 'moment';
import { first, take } from 'rxjs/operators';
import { AppEnum } from '@enums/AppEnum';
import { ClubhouseService } from './clubhouse.service';
import { AdminService } from './admin.service';
import { DB_CONFIG } from '@app/app.firebase.config';
import { Mood_Sleep } from '@interfacemood-sleep.interface';

@Injectable({
  providedIn: 'root'
})
export class HomeService extends Utilities {

  authUser: any;
  
  mantra = '';

  cobrand = new BehaviorSubject< { primary: string; darkPrimary: string; secondary: string; logoUrl: string; }>(null);
  moodSleep: Mood_Sleep


  apps = [];
  subscription = new Subscription();
  overallSession = {} as OverallSession;
  errorMessage: string;
  deviceType = [];
  recommendedGame: '';
  featuredActivities        = [];
  todaysDate                = ``;
  todaysActivities          = [];
  todaysCompliantActivities = [];
  moreActivities            = [];
  profileApps               = [];
  nonProfileApps            = [];
  showDataBoost = new BehaviorSubject(true);
  destroy$ = new Subject();
  appsSorted = new Subject();
  savingInterval: any;
  outsideModalClosed = new EventEmitter<any>();
  sleepModalClosed = new EventEmitter<any>();
  didYouKnowModalClosed = new EventEmitter<any>();
  skipAppModalClosed = new EventEmitter<any>();
  skipGameModalClosed = new EventEmitter<any>();

  constructor(
    private authService: AuthenticationService,
    private overallSessionService: OverallSessionService,
    private clubhouseService: ClubhouseService,
    public navCtrl: NavController,
    public alertController: AlertController,
    public platform: Platform,
    public modalCtrl: ModalController,
    public modalController: ModalController,
    private adminService: AdminService
  ) {
    super();
    this.initialize();
  }

  /** This is a bunch of functions used by multiple pages in the newer home design */

  /**
   * Gets the activities list for the user.
   * @returns An object of activities.
   */
  getActivities(): { today: any[]; more: any[]; featured: any[]; recommend: string; finished: any[] } {
    return { 
      today: this.todaysActivities,
      more: this.moreActivities,
      featured: this.featuredActivities,
      recommend: this.recommendedGame,
      finished: this.todaysCompliantActivities,
    };
  }

  /**
   * Gets the cobranding theme of the user.
   * @returns An object of the theme.
   */
  getCobranding() {
    if(!this.cobrand) {
      throw new Error('bad cobranding');
    } else {
      return this.cobrand;
    }
  }

  getSubdeets() {
    return new Promise((res, rej) => {
      this.adminService.getEntryById(this.authUser.subscriber_id, DB_CONFIG.subscriber_endpoint).then(sub => {
        sub.darkPrimary = this.getDarker(sub.primary);
        this.cobrand.next({
          primary: sub.primary,
          darkPrimary: sub.darkPrimary,
          secondary: sub.secondary,
          logoUrl: sub.logoUrl,
        });
        res(sub);
      });
    });
  }

  /**
   * Gets the authenticated user.
   * @returns The authenticated user.
   */
  getAuthUser() {
    return new Promise((res, rej) => {
      this.authService.userSubject.subscribe(user => {
        if(user){
          res(user);
        } else {
          res(null);
        }
      })
    });
  }

  private async initialize() {
    await this.loadAuthUser();
    await this.getSubdeets();
    this.getApps();
    this.getMoodSleep();
  }

  /**
   * Loads the authenticated user.
   */
  private loadAuthUser(){
    return new Promise((res, rej) => {
      this.authService.userSubject
      .subscribe((user) => {
        if (user) {
          this.authUser = user;
          res(user);
        }
      });
    });
  }

  /**
   * Sets the dark primary color of the primary color.
   * @param primary The primary color in a string format.
   */
  private getDarker(primary: string) {
    let hexval = this.convertToHexObject(primary);
    hexval = this.darkenRGB(hexval);
    return this.convertHexToString(hexval);
  }

  /**
   * Converts a hex color to string format.
   * @param hex The color in hex format.
   * @returns The hex color in a string format.
   */
  private convertHexToString(hex: any): string {
    const keys = Object.keys(hex);
    let hexStr = '#';
    for (const key of keys) {
      if(hex[key] < 16) {
        hexStr = hexStr.concat('0');
      }
      hexStr = hexStr.concat(hex[key].toString(16));
    }
    return hexStr;
  }

  /**
   * Darkens the RBG values.
   * @param rgb The original RGB color.
   * @returns The darkened RBG color.
   */
  private darkenRGB(rgb: any): any {
    const keys = Object.keys(rgb);
    for (const key of keys) {
      rgb[key] -= 32;
      if (rgb[key] < 0) {
        rgb[key] = 0;
      }
    }
    return rgb;
  }

  /**
   * Converts a color string to a RGB object.
   * @param colorStr The color in a string format.
   * @returns The color in an RGB format.
   */
  private convertToHexObject(colorStr: string): {red: number, green: number, blue: number} {
    if(!colorStr){return null}
    const red = parseInt(colorStr.substring(1, 3), 16);
    const green = parseInt(colorStr.substring(3, 5), 16);
    const blue = parseInt(colorStr.substring(5), 16);
    return {red, green, blue};
  }

   /**
   * Gets the app information.
   * @param startDate startDate for Compliance
   * @param endDate
   */
    getApps(startDate?: Date, endDate?: Date): void {
      if(!startDate){
        startDate = moment().subtract(7, 'd').startOf('d').toDate();
      }
      if(!endDate){
        endDate = moment().endOf('d').toDate();
      }
      this.subscription.add(
        this.adminService.getEntries(DB_CONFIG.apps_endpoint,'name')
        .pipe(take(1))
        .subscribe((apps: IApps[]) => {
          if (this.authUser) {
              this.overallSessionService.getUserSessionByDate(this.authUser.id,
                startDate, endDate, apps, this.authUser.role)
                .subscribe(async (data: any) => {
                  if (!data) {
                    return;
                  }
                  this.apps = data.apps;
  
                  this.todaysActivities          = [];
                  this.todaysCompliantActivities = [];
                  this.moreActivities            = [];
                  this.profileApps               = [];
                  this.nonProfileApps            = [];
                  // loop through the apps, REWRITE THIS GARBAGE. categories is way better than seperate arrays
                  const promises = this.apps.map(async(app) => {
                    // if settings or assessment, add to the profile section of the UI
                    if (app.name === AppEnum.Settings || app.name === AppEnum.Assessment) {
                      this.profileApps.push(app);
                    } else {
                      this.nonProfileApps.push(app);
                      // if the app hasn't met compliance percentage, and the app hasn't been played today, add it today's activities
                      if (app.compliancePercentage < app.goalPercentage && (app.daysSinceLastUse >= 1 || app.daysSinceLastUse === '+7')) {
                        app['category'] = 'today';
                        this.todaysActivities.push(app);
                      // if the app has been played today, add to more activities
                      } else if(app.daysSinceLastUse === 0) {
                        app['category'] = 'today-compliant';
                        this.todaysCompliantActivities.push(app);
                        this.moreActivities.push(app);
                      }else {
                        app['category'] = 'more';
                        this.moreActivities.push(app);
                      }            
                    }
                  });
                  await Promise.all(promises);
                  this.recommendedGame = data.recommendedGame;
                  this.sortApps();
                  this.appsSorted.next();
  
                  // if there are no more activities for today, do not show data boost message
                  if (this.todaysActivities.length === 0 ) {
                    this.showDataBoost.next(false);
                  }
    
                }, (error) => {
                  console.log('error', error);
                });
          } else {
            _.each(apps, (app: IApps) => {
              const hasPermission = !app.active ? false : _.contains(app.permissions, this.authUser.role);
              app.hasPermission = hasPermission;
            });
            this.apps = apps;
            this.showDataBoost.next(false);
          }
        })
      );
    }

  /**
   * Gets the app information.
   */
  async getApps3(startDate?: any, endDate?: any) {
    if (!this.authUser) {return []}
    if(!startDate){
      startDate = moment().subtract(7, 'd').toDate();
    }
    if(!endDate){
      endDate = moment().add(1,'d').toDate();
    }
    if(typeof startDate === 'object') {startDate = moment(startDate).toDate();}
    if(typeof endDate === 'object') {endDate = moment(endDate).toDate();}

    const apps = await this.adminService.getEntries(DB_CONFIG.apps_endpoint,'name').pipe(first()).toPromise()
    const data = await this.overallSessionService.getUserSessionByDate(this.authUser.id, startDate, endDate, apps, this.authUser.role).pipe(first()).toPromise()
    const relevantApps = [];
    const promises = data.apps.map(async(app) => {
      // if settings or assessment, compliance doesn't matter
      if (app.name !== AppEnum.Settings && app.name !== AppEnum.Assessment) {
        relevantApps.push(app);
      }
    });
    await Promise.all(promises);
    return relevantApps;
  }

  /**
   * Gets the app information.
   */
   getApps2(weeksBack: number) {
    const currSunday= moment().day(7);
    let currDate    = currSunday.subtract((7 * weeksBack), 'days').toDate();
    let oneWeekBack = currSunday.subtract(7, 'days').toDate();
    if(!weeksBack) {
      currDate    = moment().toDate();
    }

    return new Promise((res,rej) => {
      this.adminService.getEntries(DB_CONFIG.apps_endpoint,'name')
      .pipe(first())
      .subscribe((apps: IApps[]) => {
        if (!this.authUser) { this.authUser = {id: 'a5JZUQfikAT9cm0Uj2sGQS9cZ4i2', role: RoleEnum.Client}}
          this.overallSessionService.getUserSessionByDate(this.authUser.id, oneWeekBack, currDate, apps, this.authUser.role)
            .subscribe(async (data: any) => {
              if (!data) {
                return;
              }
              const relevantApps = [];
              const promises = 
              data.apps.map(async(app) => {
                // if settings or assessment, compliance doesn't matter
                if (app.name !== AppEnum.Settings && app.name !== AppEnum.Assessment) {
                  relevantApps.push(app);
                }
              });
              await Promise.all(promises);
              res(relevantApps);
          });
      })
    });
  }

  /**
   * Sorts the apps in the recommended order.
   */
  sortApps(): void {
    // places the Happy Place app in the beginning of today's activities 
    this.todaysActivities.forEach((app, i) => {
      if (app.name === AppEnum.HappyPlace) {
        this.todaysActivities.splice(i, 1);
        this.todaysActivities.unshift(app);
      }
    });

    this.nonProfileApps.forEach((app, i) => {
      if (app.name === AppEnum.HappyPlace) {
        this.nonProfileApps.splice(i, 1);
        this.nonProfileApps.unshift(app);
      }
    });

    // places the Clubhouse app in the beginning of today's activities if there is a live meeting
    if (this.clubhouseService.liveMeeting) {
      this.todaysActivities.forEach((app, i) => {
        if (app.name === AppEnum.Clubhouse) {
          this.todaysActivities.splice(i, 1);
          this.todaysActivities.unshift(app);
        }
      });

      this.nonProfileApps.forEach((app, i) => {
        if (app.name === AppEnum.Clubhouse) {
          this.nonProfileApps.splice(i, 1);
          this.nonProfileApps.unshift(app);
        }
      });
    }
  }

  /**
   * Checks if Brain Game is in the more activities array.
   * @returns Whether the user has already played Brain Game.
   */
  checkBrainGame(): boolean {
    return this.moreActivities.findIndex((app) => app.name === AppEnum.BrainGame) !== -1;
  }

  getMoodSleep() {
    this.adminService.getEntryByUserId(DB_CONFIG.mood_sleep_endpoint).then(data => {
      if(data){
        this.moodSleep = data;
      } else {
        this.moodSleep = {} as any;
        this.moodSleep['responses'] = [];
      }
    })
  }

  saveMoodSleep(response: {sleep, mood}) {
    if(!this.moodSleep){
      throw new Error('Users Mood Sleep did not load');
    }
    this.moodSleep.responses.push(response);
    this.adminService.saveEntryByUserId(this.moodSleep, DB_CONFIG.mood_sleep_endpoint);
  }

  getTodayMoodSleep() {
    // starting info
    let mood_sleeps = this.moodSleep as any;
    mood_sleeps = mood_sleeps.responses.filter(data => {
      if(!data.created_date || Object.keys(data.created_date).length === 0){return;}
      const today = moment().startOf('d');
      const response_time = moment(data.created_date);
      return today.diff(response_time, 'd') === 0;
    });
    if(mood_sleeps.length === 0){throw new Error('no starting mood today')}
    const mood = mood_sleeps[0].mood;
    return mood;
  }
}
