import { Injectable } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { Utilities } from '@common/utilities';
import { AppEnum } from '@enums/AppEnum';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { MoodEnum } from '@enums/MoodEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { RoleEnum } from '@enums/RoleEnum';
import { bcScore } from '@models/dataTrackerModels/bcScore.model';
import { DataTracker } from '@models/dataTrackerModels/dataTracker2.model';
import { statNumTracker } from '@models/dataTrackerModels/statNumTracker.model';
import { User } from '@models/user.interface';
import moment from 'moment';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';
import { HomeService } from './home.service';
import { OverallSessionService } from './overall-session.service';
import { first } from 'rxjs/operators';
import { ProgressService } from './progress.service';
import { brainGameDefaultConfig } from '@app/app.constants.config';


@Injectable({
  providedIn: 'root'
})
export class TrackerProcessingService extends Utilities {
    
  authUser: User;
  compliances = [];
  currWeekStatTracker = new statNumTracker('test');
  prevWeekStatTracker = new statNumTracker('test');

  sleepTimes = [];
  moodNums = [];

  bcScore = new bcScore();
  startDate;
  endDate;

  loaded = new Subject<boolean>();

  suggestedUse = {
    yahootie: 3,
    braingame: 5,
    assessment: 12,
    clubhouse: 1,
    happyplace: 5,
    settings: 0,
    general: 0
  };
  processing = false;

  constructor(
    private authService: AuthenticationService,
    private adminService: AdminService,
    private homeService: HomeService,
    private progressService: ProgressService,
    private sessionService: OverallSessionService
  ) {
    super();
    this.init();
  }

  init() {
    // this.adminService.duplicateEntry(DB_CONFIG.data_tracker_endpoint,'kD58b1c99BiJgZ0RrOaX',7);
    this.loadAuthUser();
    // this.getBCScores(0);
  }

  /**
   * fills the bcscore progress objects
   */
  async fillProgressObj(userId) {
    let bcScoreObject = new bcScore();
    return Promise.resolve().then(async (res) => {
      // console.log('current week stat tracker', this.currWeekStatTracker);
      try {
        bcScoreObject.speed          = this.totalSpeed();
        bcScoreObject.intelligence   = await this.totalIntelligence(userId);
        bcScoreObject.problemSolving = this.totalProblemSolving();
        bcScoreObject.focus          = this.totalFocus();
        bcScoreObject.memory         = this.totalMemory();
        bcScoreObject.mood           = this.totalMood();
        bcScoreObject.sleep          = this.totalSleep();
      } catch(error) {
        console.error(error);
      }
      
      return;
    }).then(res => {
      bcScoreObject.bcScore = this.generateBCScore(bcScoreObject);
      bcScoreObject.bcCompliance = this.bcCompliance();
      if(bcScoreObject.bcScore > 100) {bcScoreObject.bcScore = 100;}
      if(bcScoreObject.bcCompliance > 100) {bcScoreObject.bcCompliance = 100;}
      return;
    }).then(res => {
      this.loaded.next(true);
      // this.saveBCScores();
      this.processing = false;
      this.bcScore = bcScoreObject;
      return bcScoreObject;
    })
    

    
  }

  private loadAuthUser() {
    this.authService.userSubject
    .subscribe((user) => {
      if (user) {
        this.authUser = user;
      } else {
        this.authUser = {
          id: 'a5JZUQfikAT9cm0Uj2sGQS9cZ4i2',
          role: RoleEnum.Client
        } as User;
      }
    });
  }

  /**
   * finds the compliance of a given app name in the saved compliances
   * @param appName app you want to know compliance of
   * @returns the compliance percentage of said app
   */
  findCompliance(appName: AppEnum): number {
    const found = this.compliances.find(compliance => compliance.name === appName);
    if(!found){
      console.log(`${appName} not found in compliance`)
      return 0;
    }
    return found.compliancePercentage;
  }

  /**
   * gets average strategy score
   * @returns 
   */
  private getBgStrategyScore() {
    let totalStrategyScore = 0;
    let divisor = 0;
    if(this.currWeekStatTracker.BrainGame.sudokuScore.mean !== 0) {
      totalStrategyScore += (this.currWeekStatTracker.BrainGame.sudokuScore.mean / 30) * 100;
      divisor++;
    }
    if(this.currWeekStatTracker.BrainGame.slidingScore.mean !== 0) {
      totalStrategyScore += (this.currWeekStatTracker.BrainGame.slidingScore.mean / 30) * 100;
      divisor++;
    }
    if(this.currWeekStatTracker.BrainGame.lemonScore.mean !== 0) {
      totalStrategyScore += (this.currWeekStatTracker.BrainGame.lemonScore.mean / 50) * 100;
      divisor++;
    }
    if(this.currWeekStatTracker.BrainGame.trailScore.mean !== 0) {
      totalStrategyScore += (this.currWeekStatTracker.BrainGame.trailScore.mean / 2000) * 100;
      divisor++;
    }
    if(this.currWeekStatTracker.BrainGame.towerScore.mean !== 0) {
      totalStrategyScore += (this.currWeekStatTracker.BrainGame.towerScore.mean / 5000) * 100;
      divisor++;
    }
    if(this.currWeekStatTracker.BrainGame.largeSudokuScore.mean !== 0) {
      totalStrategyScore += (this.currWeekStatTracker.BrainGame.largeSudokuScore.mean / 100) * 100;
      divisor++;
    }
    if(divisor === 0) {
      return 0;
    } 
    totalStrategyScore /= divisor;
    return totalStrategyScore;
  }

  // (((XXXX - average time in ms to answer Stroop questions) / XXXX)*100) + 100
  private averageYahootieTimeCalculation(yahootieArray: any[], XXXX?): number{
    if(!XXXX) {
      XXXX = 80;
    }
    let sum = 0;
    const ms = 1000;
    XXXX *= ms;
    let len = yahootieArray.length;
    for(let yahootie of yahootieArray) {
      if(yahootie && yahootie < 62000) {
        sum += (((XXXX - yahootie) / XXXX) * 100) + 100;
      } else {
        len--
      }
    }
    if(!len){return 0}
    return sum/len;
  }

  private bindPercentages(average) {
    if(average < 0){
      return 0;
    } else if(average > 100){
      return 100;
    } else {
      return average;
    }
  }

  private async checkIfInWeek(arr: any[]) {
    if(!arr){return []}
    // filter by time
    const times = arr.map(data => {
      if(!data.created_date || Object.keys(data.created_date).length === 0){return;}
      const response_time = moment(data.created_date);
      if(response_time.isAfter(this.startDate) && response_time.isBefore(this.endDate)) {
        return data;
      } else {
        return;
      }
    });
    await Promise.all(times);
    const responses = times.filter(n => n);
    return responses;
  }

  /**
   * Finds the total speed calulation
   */
  totalSpeed() {
    const game1 = this.bgSpeed();
    const game2 = this.yahootieSpeed();
    const trueFinal = (game1 + game2) / 2;
    return trueFinal;
  }

  /**
   * Finds our brain game speed calculation
   */
  bgSpeed() {
    const totalStrategyScore = this.bindPercentages(this.getBgStrategyScore());
    let triviaTime = this.currWeekStatTracker.BrainGame.triviaTime.mean/1000;
    let number_of_questions = this.currWeekStatTracker.BrainGame.question_number.mean;
    if(!number_of_questions) {return 0}
    const complex_triviaRT = (1 - ((triviaTime/number_of_questions) / 20) + 1) * 100; // should be questions
    const triviaRT = this.bindPercentages(complex_triviaRT);
    const compliance = this.findCompliance(AppEnum.BrainGame);
    let diffMultiplier = this.currWeekStatTracker.BrainGame.difficulty.mean;
    let diffMult = 1.0;
    // easy
    if(diffMultiplier >= 0 && diffMultiplier < 1.5) {
      diffMult = 1.0;
    // medium
    } else if (diffMultiplier >= 1.5 && diffMultiplier < 2.5) {
      diffMult = 1.1;
    // hard
    } else {
      diffMult = 1.2;
    }
    const final = 

    ((((totalStrategyScore + triviaRT) / 2) * diffMult) * 0.75) + (compliance * 0.25);

    return final;
  }

  /**
   * Finds our yahootie speed calculation
   */
  yahootieSpeed() {
    const tracker = this.currWeekStatTracker.Yahootie;

    const yahootieArr = [
      tracker.StroopTime.mean,
      tracker.FlankerTime.mean,
      tracker.PatternTime.mean,
      tracker.Simple_MathTime.mean,
      tracker.Complex_MathTime.mean
    ]
    const gameSpeed = this.bindPercentages(this.averageYahootieTimeCalculation(yahootieArr));
    const gameScore = this.bindPercentages((tracker.score.mean / 240) * 100);
    const gameScoreSpeedTrade = (gameSpeed + gameScore) / 2;
    const compliance = this.findCompliance(AppEnum.Yahootie);
    let final = ((gameScoreSpeedTrade * 0.75) + (compliance * 0.25));

    return final;
  }

  /**
   * Finds the total x calulation
   */
  totalIntelligence(userId) {
    return new Promise((res,rej) => {
      const game1 = this.bgIntell();
      this.clubhouseIntell(userId).then((game2: number) => {
        res((game1+ game2) / 2);
      }); 
    });
    
   
  }

  /**
   * Finds our brain game intelligence calculation
   */
  bgIntell() {
    const triviaAccuracy = this.currWeekStatTracker.BrainGame.triviaAcc.mean * 100;
    let diffMultiplier = this.currWeekStatTracker.BrainGame.difficulty.mean;
    let diffMult = 1.0;
    // easy
    if(diffMultiplier >= 1 && diffMultiplier < 1.5) {
      diffMult = 1.0;
    // medium
    } else if (diffMultiplier >= 1.5 && diffMultiplier < 2.5) {
      diffMult = 1.1;
    // hard
    } else {
      diffMult = 1.2;
    }

    const compliance = this.findCompliance(AppEnum.BrainGame);

    return ( ( (triviaAccuracy * diffMult) * 0.75) + (compliance * 0.25) );
  }

  /**
   * Finds our clubhouse intelligence calculation
   */
  clubhouseIntell(userId) {
    return new Promise((res,rej) => {
      // Number of times presentations accessed
      const presentationAccessed = this.currWeekStatTracker.Clubhouse.presentationsReviewed.mean;
      this.getClubhouseAttendance(userId).then(data => {
        if (!data || !(data.length)) {
          // throw new Error('didnt attend any presentations, causing a divide by 0');
          res(0);
        }
        const presentationAttended = data.length;
        const compliance = presentationAttended?100:0;
        // Compliance + ((Number of times presentation accessed / Number of presentations attended) * 0.1)
        res((compliance * 0.9) + (this.bindPercentages((presentationAccessed / presentationAttended) * 100) * 0.1));
      });
    });
  }

  async getClubhouseAttendance(userId) {
    const sub_clubhouse = await this.adminService.getEntryById(this.authUser.subscriber_id, DB_CONFIG.meeting_endpoint);
    if(!sub_clubhouse){return []}
    let meetings = await this.checkIfInWeek(sub_clubhouse.meetings);
    // let meetings = sub_clubhouse.meetings;
    meetings = meetings.filter(meeting => {
      return meeting.attendees.includes(userId)
    })
    return meetings;
    
  }

  /**
   * Finds our total problem solving calculation
   */
  totalProblemSolving() {
    const game1 = this.bgProblemSolving();
    const game2 = this.yahootieProblemSolving();
    return (game1 + game2) / 2;
  }

  /**
   * Finds our bg problem solving calculation
   */
  bgProblemSolving() {
    const totalStrategyScore = this.bindPercentages(this.getBgStrategyScore());
    const compliance = this.findCompliance(AppEnum.BrainGame);
    let diffMultiplier = this.currWeekStatTracker.BrainGame.difficulty.mean;
    let diffMult = 1.0;
    // easy
    if(diffMultiplier >= 1 && diffMultiplier < 1.5) {
      diffMult = 1.0;
    // medium
    } else if (diffMultiplier >= 1.5 && diffMultiplier < 2.5) {
      diffMult = 1.1;
    // hard
    } else {
      diffMult = 1.2;
    }

    return ((totalStrategyScore * diffMult) * 0.75) + (compliance * 0.25);
  }

  /**
   * Finds our yahootie problem solving calculation
   */
  yahootieProblemSolving() {
    const yahootieArr = [
      this.currWeekStatTracker.Yahootie.PatternTime.mean
    ]
    const gameSpeedPattern = this.bindPercentages(this.averageYahootieTimeCalculation(yahootieArr));
    const compliance = this.findCompliance(AppEnum.Yahootie);

    return ((gameSpeedPattern * 0.75) + (compliance * 0.25));
  }

  /**
   * Finds our total memory calculation
   */
  totalMemory() {
    const game1 = this.bgMemory();
    const game2 = this.yahootieMemory();
    const game3 = this.generalMemory();

    return (game1 + game2 + game3) / 3;
  }

  /**
   * Finds our brain game memory calculation
   */
  bgMemory() {
    const totalStrategyScore = this.bindPercentages(this.getBgStrategyScore());
    const triviaAccuracy = this.currWeekStatTracker.BrainGame.triviaAcc.mean * 100;
    const compliance = this.findCompliance(AppEnum.BrainGame);
    let diffMultiplier = this.currWeekStatTracker.BrainGame.difficulty.mean;
    let diffMult = 1.0;
    // easy
    if(diffMultiplier >= 1 && diffMultiplier < 1.5) {
      diffMult = 1.0;
    // medium
    } else if (diffMultiplier >= 1.5 && diffMultiplier < 2.5) {
      diffMult = 1.1;
    // hard
    } else {
      diffMult = 1.2;
    }
    const bgRating = (((totalStrategyScore + triviaAccuracy) / 2) * diffMult );

    return (bgRating * 0.75) + (compliance * 0.25);
  }

  /**
   * Finds our yahootie memory calculation
   */
  yahootieMemory() {
    const tracker = this.currWeekStatTracker.Yahootie;
    const yahootieArr = [
      tracker.Simple_MathTime.mean,
      tracker.Complex_MathTime.mean
    ]
    const gameScoreMemory = this.bindPercentages(this.averageYahootieTimeCalculation(yahootieArr));

    const totalYahooties = (tracker.correctYahootie.mean + tracker.missedYahootie.mean);
    let yahootieAcc;
    if(totalYahooties) {
      yahootieAcc = tracker.correctYahootie.mean / totalYahooties;
    } else {
      yahootieAcc = 0;
    }
    
    const claimedYahooties = (tracker.correctYahootie.mean + tracker.wrongYahootie.mean);
    let yahootieMistakes;
    if(claimedYahooties) {
      yahootieMistakes = 1 - (tracker.wrongYahootie.mean / claimedYahooties);
    } else {
      yahootieMistakes = 0;
    }
    const yahootieScore = ((yahootieAcc + yahootieMistakes) / 2) * 100;
    const compliance = this.findCompliance(AppEnum.Yahootie);
    const yahootieRating = ((gameScoreMemory + yahootieScore) / 2);

    return ((yahootieRating * 0.75) + (compliance * 0.25));
  }

  /**
   * Finds our general memory calculation
   */
  generalMemory() {
    const maxMood = MoodEnum.VeryHappy + 1;
    // if mood doesn't exist return a 0
    if(!this.currWeekStatTracker.General.moodNum.n){return 0;}
    const qolMood = ((this.currWeekStatTracker.General.moodNum.mean + 1)/ maxMood) * 100;

    const compliance = 100; // talk more with ellen, we made decision on this before
    return (qolMood * 0.75) + (compliance * 0.25);
  }

  /**
   * Finds our total focus calculation
   */
  totalFocus() {
    const game1 = this.bgFocus();
    const game2 = this.yahootieFocus();
    const game3 = this.hpFocus();
    return (game1 + game2 + game3) / 3;
  }

  /**
   * Finds our total focus calculation
   */
  bgFocus() {
    const totalStrategyScore = this.bindPercentages(this.getBgStrategyScore());
    const compliance = this.findCompliance(AppEnum.BrainGame);
    let diffMultiplier = this.currWeekStatTracker.BrainGame.difficulty.mean;
    let diffMult = 1.0;
    // easy
    if(diffMultiplier >= 1 && diffMultiplier < 1.5) {
      diffMult = 1.0;
    // medium
    } else if (diffMultiplier >= 1.5 && diffMultiplier < 2.5) {
      diffMult = 1.1;
    // hard
    } else {
      diffMult = 1.2;
    }

    return (totalStrategyScore * 0.75) + (compliance * 0.25);
  }

  /**
   * Finds our yahootie focus calculation
   */
  yahootieFocus() {
    const yahootieArr = [
      this.currWeekStatTracker.Yahootie.StroopTime.mean,
      this.currWeekStatTracker.Yahootie.FlankerTime.mean
    ]
    const gameScoreFocus = this.bindPercentages(this.averageYahootieTimeCalculation(yahootieArr));
    const compliance = this.findCompliance(AppEnum.Yahootie);

    return ((gameScoreFocus * 0.75) + (compliance * 0.25));
  }

  /**
   * Finds our happy place focus calculation
   */
  hpFocus() {
    const maxMood = 5;
    const initMood = this.currWeekStatTracker.General.moodNum.mean + 1;
    if(!this.currWeekStatTracker.General.moodNum.n){
      console.error('no start mood');
      return 0;
    }
    const hpMood                  = this.currWeekStatTracker.HappyPlace.moodNum.mean + 1;
    if(!this.currWeekStatTracker.HappyPlace.moodNum.n){
      console.error('no hp mood');
      return 0;
    }
    const initProgress            = (initMood/maxMood) * 100;
    const init_progress_remaining = 100 - initProgress;
    const hp_progress_remaining   = (1 - (hpMood/maxMood)) * 100;
    const hp_impact               = 100 - hp_progress_remaining;
    const moodImprovement         = ((init_progress_remaining*0.5) + (hp_impact*0.5))
    const compliance              = this.findCompliance(AppEnum.HappyPlace);
    
    return (moodImprovement * 0.75) + (compliance * 0.25);
  }

  totalMood() {
    // Mood = average mood over time period selected
    // Mood Change = (average mood this week - average mood previous week) / average mood previous week

    const mood = this.currWeekStatTracker.General.moodNum.mean;
    const prevMood = this.prevWeekStatTracker.General.moodNum.mean;
    let moodChange;
    if(prevMood) {
      moodChange = (mood - prevMood) / prevMood;
    } else {
      moodChange = 0;
    }
   
    return {mood, moodChange};
  }

  totalSleep() {
    // Sleep = average sleep over time period selected
    // Sleep Past = IF(AND(Avg Sleep Past<=9,Avg Sleep Past>=7),0,IF(Avg Sleep Past<7,((Avg Sleep Past - 7) / 12) * 100,IF(Avg Sleep Past>9,((Avg Sleep Past - 9) / 12) * 100, "")))
    // Sleep Now =  IF(AND(Avg Sleep Now<=9,Avg Sleep Now>=7),0,IF(Avg Sleep Now<7,((Avg Sleep Now - 7) / 12) * 100,IF(Avg Sleep Now>9,((Avg Sleep Now - 9) / 12) * 100, "")))
    // Sleep Change = Sleep Now - Sleep Past
    const sleep = this.currWeekStatTracker.General.sleepTime.mean;
    const avgSleepPast = this.prevWeekStatTracker.General.sleepTime.mean;
    const sleepPast = this.sleepChange(avgSleepPast);
    const sleepNow = this.sleepChange(sleep);
    const sleepDelta = sleepNow - sleepPast;

    return this.currWeekStatTracker.General.sleepTime.mean;
  }

  sleepChange(avgSleep) {
    if(avgSleep < 7) {
      return ((avgSleep - 7) / 12) * 100
    } else if (avgSleep > 9) {
      return ((avgSleep - 9) / 12) * 100;
    } else {
      return 0;
    }
  }

  private bcCompliance() {
    const apps = Object.values(AppEnum);
    let sum = 0;
    for(let app of apps) {
      if(app === 'Settings' || app === 'General'){continue}
      const add = this.findCompliance(app);
      if (add) {
        sum += add;
      }
    }
    const total = (sum/4);
    return total;
  }

  private generateBCScore(bcScore) {
    let sum = 0;
    sum += bcScore.speed;
    sum += bcScore.intelligence;
    sum += bcScore.problemSolving;
    sum += bcScore.memory;
    sum += bcScore.focus;
    return sum/5;
  }

  async calculateComplianceFromTrackers(start_date, end_date) {
    try{
      this.compliances = await this.sessionService.getCompliance(start_date, end_date);
    } catch{
      this.compliances = [];
    }
  }

  async getTrackers2(start_date, end_date, userId){
    this.currWeekStatTracker = new statNumTracker(userId);
    // early results only needed for mood and sleep
    let currDate, oneWeekBack, twoWeekBack;
    currDate    = end_date.toJSON();
    oneWeekBack = start_date.toJSON();
    twoWeekBack = moment(start_date).subtract(7, 'd').toJSON();

    // start brain game
    let bg_responses = await this.adminService.getEntryById(userId, DB_CONFIG.bg_responses_endpoint)
    if(!bg_responses){bg_responses = {responses: []}}

    const now_results_bg = bg_responses.responses.filter(result => {
      if(!result){throw new Error('bg result saved incorrectly')}
      const test = moment(result.created_date).diff(oneWeekBack, 'd');
      return test > 0 && test < 7;
    });

    for(let result of now_results_bg){
      // easy processing
      this.currWeekStatTracker.BrainGame.gameTime       = await this.addToStatNums(result.game_time, this.currWeekStatTracker.BrainGame.gameTime);
      if(result.leaderboard > 0){this.currWeekStatTracker.BrainGame.leaderboard = await this.addToStatNums(result.leaderboard, this.currWeekStatTracker.BrainGame.leaderboard);}
      if(!!result.triviaAcc && result.triviaAcc !== Infinity){this.currWeekStatTracker.BrainGame.triviaAcc= await this.addToStatNums(result.triviaAcc, this.currWeekStatTracker.BrainGame.triviaAcc);}
      this.currWeekStatTracker.BrainGame.triviaTime     = await this.addToStatNums(result.trivia_time, this.currWeekStatTracker.BrainGame.triviaTime);
      this.currWeekStatTracker.BrainGame.sessTime       = await this.addToStatNums(result.trivia_time + result.game_time, this.currWeekStatTracker.BrainGame.sessTime);
      this.currWeekStatTracker.BrainGame.question_number= await this.addToStatNums(result.question_answer.length, this.currWeekStatTracker.BrainGame.question_number);

      // difficulty processing
      switch(result.difficulty){
        case GameDifficultyEnum.Easy:
          this.currWeekStatTracker.BrainGame.difficulty   = await this.addToStatNums(1, this.currWeekStatTracker.BrainGame.difficulty);
          break;
        case GameDifficultyEnum.Medium:
          this.currWeekStatTracker.BrainGame.difficulty   = await this.addToStatNums(2, this.currWeekStatTracker.BrainGame.difficulty);
          break;
        case GameDifficultyEnum.Hard:
          this.currWeekStatTracker.BrainGame.difficulty   = await this.addToStatNums(3, this.currWeekStatTracker.BrainGame.difficulty);
          break;
      }

      // game score processing
      switch(result.game_name){
        case PuzzleGameEnum.Lemonade:
          this.currWeekStatTracker.BrainGame.lemonScore   = await this.addToStatNums(result.game_score, this.currWeekStatTracker.BrainGame.lemonScore);
          break;
        case PuzzleGameEnum.Sliding:
          this.currWeekStatTracker.BrainGame.slidingScore = await this.addToStatNums(result.game_score, this.currWeekStatTracker.BrainGame.slidingScore);
          break;
        case PuzzleGameEnum.Sudoku:
          this.currWeekStatTracker.BrainGame.sudokuScore  = await this.addToStatNums(result.game_score, this.currWeekStatTracker.BrainGame.sudokuScore);
          break;
        case PuzzleGameEnum.Trail:
          this.currWeekStatTracker.BrainGame.trailScore   = await this.addToStatNums(result.game_score, this.currWeekStatTracker.BrainGame.trailScore);
          this.currWeekStatTracker.BrainGame.synapse_trivia
          = await this.addToStatNums((result.question_answer.length*result.triviaAcc)/result.num_of_rounds, this.currWeekStatTracker.BrainGame.synapse_trivia);
          break;
        case PuzzleGameEnum.Tower:
          this.currWeekStatTracker.BrainGame.towerScore   = await this.addToStatNums(result.game_score, this.currWeekStatTracker.BrainGame.towerScore);
          break;
        case PuzzleGameEnum.LargeSudoku:
          this.currWeekStatTracker.BrainGame.largeSudokuScore   = await this.addToStatNums(result.game_score, this.currWeekStatTracker.BrainGame.largeSudokuScore);
          break;
      }
    }
    // end brain game


    // yahootie
    let yahootie_results = await this.adminService.getEntries(DB_CONFIG.yahootie_result_report_endpoint).pipe(first()).toPromise();
    if(!yahootie_results){ throw new Error('error getting yahootie Results')}

    yahootie_results = yahootie_results.filter(result => {return !!result.playerIds;});
    // needs to be updated when multiplayer hits
    yahootie_results = yahootie_results.filter(result => {return userId === result.playerIds[0];});
    yahootie_results = yahootie_results.filter(result => {return result.timers});

    const now_results_ya = yahootie_results.filter(result => {
      if(!result){throw new Error('ya result saved incorrectly')}
      const test = moment(result.created_date).diff(oneWeekBack, 'd');
      return test > 0 && test < 7;
    });
      

    const promises = now_results_ya.map(async (result) => {
      // timer processing
      const timer_keys = Object.keys(result.timers);
      for(let timer_key of timer_keys){
        const val = result.timers[timer_key].timer;
        if(!this.currWeekStatTracker.Yahootie[timer_key]){
          console.log(`error on timer ${timer_key}`);
          continue;
        }
        this.currWeekStatTracker.Yahootie[timer_key] = await this.addToStatNums(val, this.currWeekStatTracker.Yahootie[timer_key])
      }
      if(!result.questionNum) {return;}

      // other processing
      this.currWeekStatTracker.Yahootie.correctYahootie = await this.addToStatNums(result.correctYahootie, this.currWeekStatTracker.Yahootie.correctYahootie);
      this.currWeekStatTracker.Yahootie.missedYahootie  = await this.addToStatNums(result.missedYahootie, this.currWeekStatTracker.Yahootie.missedYahootie);
      this.currWeekStatTracker.Yahootie.wrongYahootie   = await this.addToStatNums(result.wrongYahootie, this.currWeekStatTracker.Yahootie.wrongYahootie);
      this.currWeekStatTracker.Yahootie.questionNum     = await this.addToStatNums(result.questionNum, this.currWeekStatTracker.Yahootie.questionNum);
      this.currWeekStatTracker.Yahootie.skips           = await this.addToStatNums(result.skips, this.currWeekStatTracker.Yahootie.skips);
      this.currWeekStatTracker.Yahootie.score           = await this.addToStatNums(result.overall_score, this.currWeekStatTracker.Yahootie.score);
    });
    await Promise.all(promises);
    // end Yahootie


    // start Happy Place
    let happy_place_results = await this.adminService.getEntryById(userId, DB_CONFIG.happy_place_tracking_endpoint);
    if(!happy_place_results){happy_place_results = {data: []}}
    happy_place_results = happy_place_results.data;

    const now_results_hp = happy_place_results.filter(result => {
      if(!result){throw new Error('hp result saved incorrectly')}
      const test = moment(result.created_date).diff(oneWeekBack, 'd');
      return test > 0 && test < 7;
    });

    now_results_hp.map(async (now_result) => {
      this.currWeekStatTracker.HappyPlace.moodNum = await this.addToStatNums(now_result.mood, this.currWeekStatTracker.HappyPlace.moodNum)
    })
    // end Happy Place


    // start Clubhouse
    let clubhouse_results = await this.adminService.getEntryById(userId, DB_CONFIG.clubhouse_tracking_endpoint);
    if(!clubhouse_results){clubhouse_results = {tracking_data: []}}
    clubhouse_results = clubhouse_results.tracking_data;

    const now_results_c = clubhouse_results.filter(result => {
      if(!result){throw new Error('c result saved incorrectly')}
      const test = moment(result.created_date).diff(oneWeekBack, 'd');
      return test > 0 && test < 7;
    });
    now_results_c.map(async (now_result) => {
      this.currWeekStatTracker.Clubhouse.presentationsReviewed = 
      await this.addToStatNums(now_result.presentations_reviewed.length, this.currWeekStatTracker.Clubhouse.presentationsReviewed)
    })
    // end Clubhouse


    // start Mood + Sleep
    let mood_sleep_results = await this.adminService.getEntryById(userId, DB_CONFIG.mood_sleep_endpoint);
    if(!mood_sleep_results){mood_sleep_results = {responses: []}}
    mood_sleep_results = mood_sleep_results.responses;

    const now_results_mood_sleep = mood_sleep_results.filter(result => {
      if(!result){throw new Error('ms result saved incorrectly')}
      const test = moment(result.created_date).diff(oneWeekBack, 'd');
      return test > 0 && test < 7;
    });
    const early_results_mood_sleep = mood_sleep_results.filter(result => {
      if(!result){throw new Error('ms result saved incorrectly2')}
      const test = moment(result.created_date).diff(twoWeekBack, 'd');
      return test > 0 && test < 7;
    });
    now_results_mood_sleep.map(async (now_result) => {
      this.currWeekStatTracker.General.moodNum = 
      await this.addToStatNums(now_result.mood, this.currWeekStatTracker.General.moodNum);
      this.currWeekStatTracker.General.sleepTime = 
      await this.addToStatNums(now_result.sleep, this.currWeekStatTracker.General.sleepTime);
    })

    const promises3 = early_results_mood_sleep.map(async (now_result) => {
      this.prevWeekStatTracker.General.moodNum = 
      await this.addToStatNums(now_result.mood, this.prevWeekStatTracker.General.moodNum);
      this.prevWeekStatTracker.General.sleepTime = 
      await this.addToStatNums(now_result.sleep, this.prevWeekStatTracker.General.sleepTime);
    })
    await Promise.all(promises3)
    // end Mood + Sleep
      

    return true;
  }

  /**
   * takes a val and adds it to an existing statnum {mean, n, stdDev}
   * @param val value to add
   * @param statNum existing statnum to modify
   * @returns a statnum with value added to it
   */
   private addToStatNums(val: number, statNum: any) {
    const mean = statNum.mean;
    const n = statNum.n;
    const stdDev = statNum.stdDev;

    return new Promise<{mean, stdDev, n}>((res,rej) => {
        const newN = n + 1;
        let newMean = mean * n;
        newMean += val;
        newMean /= newN;

        // new summation, (o^2 + u^2) * N
        let newStdDev = (Math.pow(stdDev, 2) + Math.pow(mean, 2)) * n;
        // do std dev now, ((Ex^2 + xi^2) / N+1) - ui^2
        newStdDev = ((newStdDev + Math.pow(val, 2)) / newN) - Math.pow(newMean, 2);
        newStdDev = Math.sqrt(newStdDev);
        res({mean: newMean, stdDev: newStdDev, n: newN});
    });
  }

  saveBCScores() {
    // save an array of bcscores with week ranges and scores
    // this.adminService.saveEntryByUserId(this.bcScore, DB_CONFIG.brain_charge_scores_endpoint);
  }

  /**
   * gets the brain charge scores, also will start filling progress obj if needed
   * @param weeksBack weeks back we are going to see this entry
   */
  async getBCScores(start_date?, end_date?, user?) {
    if(user){
      this.authUser = user
    }
    if(this.currWeekStatTracker){
      this.currWeekStatTracker = new statNumTracker('test');
      this.prevWeekStatTracker = new statNumTracker('test');
    }
    if(!start_date){
      start_date = moment();
    }
    if(!end_date) {
      end_date = moment().add(1, 'w');
    }
    this.startDate = start_date;
    this.endDate = end_date;
    let bcScore = await this.adminService.getEntryById(this.authUser.id, DB_CONFIG.brain_charge_scores_endpoint);
    if(!!bcScore) {
      this.bcScore = bcScore[0];
    } else {
      await this.calculateComplianceFromTrackers(start_date, end_date);
      await this.getTrackers2(start_date, end_date,this.authUser.id);
      const test = await this.fillProgressObj(this.authUser.id);
      // save it
      this.saveBCScores();
      return test;
    }
  }

  /**
   * 
   * @param start_date moment variable
   * @param end_date moment variable
   * @param userId string
   */
  async loopBcScoresWeekly(start_date, end_date, user){
    let temp_start_date = moment(start_date);
    let temp_end_date = moment(start_date).add(1, 'w');
    const bcScores = [];
    while(temp_end_date.isBefore(end_date)){
      let bcScore = await this.getBCScores(temp_start_date, temp_end_date, user) as any;
      bcScore = {...bcScore, start_date: moment(temp_start_date), end_date: moment(temp_end_date)};
      bcScores.push(Object.assign(new Object(),bcScore));
      temp_start_date.add(1, 'w');
      temp_end_date.add(1, 'w');
      // return;
    }
    // const test = await Promise.all(bcScores)
    return bcScores;
  }
}
