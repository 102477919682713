import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '@services/assessment.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.page.html',
  styleUrls: ['./assessment.page.scss']
})
export class AssessmentPage implements OnInit {

  current_quiz = 1;
  current_progress = 0;
  setup_done = false;
  
  constructor(
    private assService: AssessmentService
  ){}

  ngOnInit(): void {
    this.assService.setup_done.subscribe(val => {
      this.setup_done = val;
    });

    this.assService.current_quiz.subscribe(val => {
      this.current_quiz = val;
    })

    this.assService.current_progress.subscribe(val => {
      this.current_progress = val;
    })
      
  }
}
