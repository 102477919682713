import { Injectable } from '@angular/core';
import { Presentation } from '../common/models/presentation.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/compat/firestore';
import { NavController } from '@ionic/angular';
import { DB_CONFIG } from '../app.firebase.config';
import { map, concatMap, first } from 'rxjs/operators';
import { Answer } from '../common/models/answer.model';
import { User } from '../common/models/user.interface';
import { Utilities } from '../common/utilities';
import { Meeting } from '../common/models/meeting.model';
import { IUser } from '@interface/user.interface';
import { IBGLeaderboard } from '@interfacebgLeaderboard.interface';
import {v4 as uuid} from 'uuid';
import { AuthenticationService } from './authentication.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends Utilities {

  answerCollection: AngularFirestoreCollection<Answer>;
  private answerDoc: AngularFirestoreDocument<Answer>;
  private lastAnswer: any;

  usersCollection: AngularFirestoreCollection<User>;
  private usersDoc: AngularFirestoreDocument<User>;
  private lastUser: any;

  entryCollection: AngularFirestoreCollection;
  private entryDoc: AngularFirestoreDocument;
  private lastEntry: any;
  private lastTable: string;

  private limit = 200; 
  user: IUser;

  constructor(
    private afs: AngularFirestore,
    public navCtrl: NavController,
    private afAuth: AngularFireAuth,
    private authService: AuthenticationService
  ) {
    super();
    this.init();
  }

  init() {
    // idk if this is still needed
    this.answerCollection = this.afs.collection<Answer>(DB_CONFIG.answer_endpoint);
    this.usersCollection = this.afs.collection<User>(DB_CONFIG.user_endpoint);

    // after the user loads, initialize some stuff
    this.authService.userSubject.subscribe(user => {
      this.user = user;
    })

  }

  /**
   * Gets the answers from the Firestore.
   * @returns An observable to the answers.
   */
  getAnswers(): Observable<Answer[]> {
    this.answerCollection = this.afs.collection<Answer>(
      DB_CONFIG.answer_endpoint,
      ref => ref.orderBy('details').limit(this.limit)
    );

    return this.answerCollection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as Answer;

            this.lastAnswer = a.payload.doc;

            // Get document id
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data };
          });
        })
      );
  }

  /**
   * Sets the last entry and table to null.
   */
  resetGetEntries(): void {
    this.lastEntry = null;
    this.lastTable = null;
  }

  checkIfIdMatchesCurrentUser(id: string) {
    if(!this.user){throw new Error('user is not loaded')}
    return id === this.user.id;
  }

  /**
   * Get documents from a table in the Firebase.
   * @param table The DB_CONFIG table you are searching. REQUIRED
   * @param sortBy The field name you'd like to sort by.
   * @param whereField The field name you'd like to search.
   * @param whereTruth The field value for your whereField you're searching for.
   * @param descending Sets to true if you'd like the sort by to be descending.
   * @returns An observable to subscribe to. then means success, catch means a failure to get from database.
   */
  getEntries(table: string, sortBy?: string, whereField?: string, whereTruth?: any, descending?: boolean, limit?: number): Observable<any[]> {
    let orderByOption = 'asc' as any;
    if (descending) {
      orderByOption = 'desc';
    }

    if ( whereField && whereTruth) {
      this.entryCollection = this.afs.collection(
        table,
        ref => ref.where(whereField, '==', whereTruth).limit(limit)
      );
    } else if(sortBy) {
      this.entryCollection = this.afs.collection(
        table,
        ref => ref.orderBy(sortBy, orderByOption).limit(limit)
      );
    } else {
      this.entryCollection = this.afs.collection(table);
    }

    const entryData = this.entryCollection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data();

            this.lastEntry = a.payload.doc;

            // Get document id
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data } as any;
          });
        })
      );
      return entryData;
  }

  /**
   * Saves the entry to the database.
   * @param entry The entry to save to the database.
   * @param table The table to save to. Use {@link DB_CONFIG} to find endpoints.
   * @returns A promise that returns id on success. Otherwise, throws an error.
   */
  async saveEntry(entry: any, table: string): Promise<string> {
    if(!entry.created_date) {
      entry.created_date = new Date().toJSON();
    }
    entry.modified_date = new Date().toJSON();
    this.entryCollection = await this.afs.collection(table);
    console.log('saving', entry);

    try {
      const res = await this.entryCollection.add({...entry});
      return res.id;
    } catch (error) {
      throw error;
    }
  }

  /**
   * saves entry to our database NEWER(02/24/2022)
   * @param entry the entry to save to the database
   * @param table the table to save to. use 'DB_CONFIG' to find endpoints. under src/app/firebase.config
   * @returns a promise that returns id on success. otherwise throws an error
   */
   async saveEntryById(entry: any, table: string) {
     if(!entry) {
       return;
     }
    if(!entry.id) {
      entry.id = uuid();
    }

    if(typeof entry === 'object') {
      const keys = Object.keys(entry);
      keys.forEach(key => {
        const entryField = entry[key];
        if(Array.isArray(entryField)) {
          entryField.forEach((ent,index) => {
            if(typeof ent !== 'object') {return;}
            if(!ent){return}
            let objCopy = new Object();
            if(!ent.created_date) {
              ent.created_date = new Date().toJSON();
            }
            if(!ent.modified_date) {
              ent.modified_date = new Date().toJSON();
            }
            Object.assign(objCopy, ent);
            entryField[index] = objCopy;
          });
        } else if(typeof entryField === 'object') {
          if(!entryField){return}
          let objCopy3 = new Object();
          Object.assign(objCopy3, entryField);
          entry[key] = objCopy3;
        }
      })
    }

    let objCopy2 = new Object();
    Object.assign(objCopy2, entry);
    entry = objCopy2;

    if(!entry.created_date || typeof(entry.created_date) === 'object') {
      // entry.created_date = new Date();
      entry.created_date = new Date().toJSON();
    }
    entry.modified_date = new Date().toJSON();
    try{
      await this.afs.doc<any>(`${table}/${entry.id}`).set(entry).then(result => {
        console.log('saving', entry);
      }).catch(err => {
        return err;
      });
    } catch(e){
      throw new Error(e);
    }
    return entry.id;
  }

  /**
   * saves entry to our database, adds user id as the id to the database entry automatically
   * @param entry the entry to save to the database
   * @param table the table to save to. use 'DB_CONFIG' to find endpoints. under src/app/firebase.config
   * @returns a promise that returns id on success. otherwise throws an error
   */
  saveEntryByUserId(entry: any, table: string) {
    entry['id'] = this.user.id;
    return this.saveEntryById(entry,table);
  }

  /**
   * get entry from DB based on the id
   * @param id entry id
   * @returns a promise that returns the obj + id
   */
  getEntryById(id: string, table: string): Promise<any> {
    if(!id){
      throw new Error(`${table} and ${id}`);
    }
    this.entryCollection = this.afs.collection(table);
    return this.entryCollection.doc(id).ref.get().then((doc) => {
      let retVal = doc.data() as any;
      if(!retVal) { return null; }
      retVal['id'] = doc.id;
      return retVal;
    }).catch(err => {
      throw new Error(`ID: ${id}, TABLE: ${table}`);
    });
  }

  /**
   * get entry from DB based on the id
   * @param id entry id
   * @returns a promise that returns the obj + id
   */
   async getEntryByUserId(table: string): Promise<any> {
    for(let i = 0; i < 5; i++){
      if(this.user){
        continue;
      }
      await this.sleep(1000);
    }
    if(!this.user){
      // this.user = {
      //   id: '2WWJxmbsEwfemYc5n5pBtsldbz03'
      // } as any;
      // return Promise.resolve(false);
      throw new Error(`User doesn't exist when getting an entry by the user for table ${table}`)
    }
    const id = this.user.id;
    return this.getEntryById(id,table);
  }

  /**
   * Gets a reference to the database and updates it with changes.
   * @param entry Any object.
   * @param table The database config endpoint.
   * @returns A promise that returns the id of the entry.
   */
  async updateEntry(entry: any, table: string): Promise<any> {
    const path =  table + '/' + entry.id;
    // actually update the modified date now
    entry.modified_date = new Date();

    this.entryDoc = this.afs.doc<any>(path);
    await this.entryDoc.update(entry);
    console.log('updating', entry);
    return entry.id;
  }

  /**
   * Removes an entry from the database.
   * @param id The id of the entry.
   * @param table The db endpoint for the entry.
   * @returns Returns a promise that returns on completion.
   * @throws An error if the deletion fails.
   */
  async deleteEntry(id: string, table: string) {
    try {
      const res = await this.afs.doc(`${table}/${id}`).delete();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getCollection(table: string) {
    return this.afs.collection(table).get();
  }

  replaceIdWithUid(entry, table) {
    if(!this.user){
      return false;
    }
    this.deleteEntry(entry.id, table);
    entry.id = this.user.uid;
    this.saveEntryById(entry, table);
    return entry;
  }

  /**
   * Gets entries from a table in our database from a given start date to a given end date.
   * AdminService must be used to save entries that are being searched with this
   * because the field 'created_date' is used for dates.
   * @param table The database table to be searched.
   * @param startDate The date to start search from.
   * @param endDate The date to end search at.
   * @param whereField Where the field is that we are looking for e.g. "user_id".
   * @param whereTruth Where the truth is that we are looking for e.g. "AxY6739PolEE223Am".
   * @returns An Observable to subscribe to that returns an array of entries within the dates.
   */
  getEntriesByDate(table: string, startDate: any, endDate: any, whereField?: string, whereTruth?: string, dateField?: string): Observable<any> {
    if(whereField && whereTruth) {
      this.entryCollection = this.afs.collection<any>(
        table,
        ref => ref.orderBy('created_date').startAt(startDate).endAt(endDate)
        .where(whereField, '==', whereTruth)
      );
    } else {
      this.entryCollection = this.afs.collection<any>(
        table,
        ref => ref.orderBy('created_date').startAt(startDate).endAt(endDate)
      );
    }
    if(dateField) {
      this.entryCollection = this.afs.collection<any>(
        table,
        ref => ref.orderBy(dateField).startAt(startDate).endAt(endDate)
        .where(whereField, '==', whereTruth)
      );
    }
    
    return this.entryCollection.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);

          return data;
        })
      );
  }

  /**
   * Sets the last entry to null.
   * @returns Whether the last entry was set to null
   */
  async reset(): Promise<boolean> {
    try {
      this.lastEntry = null;
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Duplicates an entry x amount of times in the same table with slightly varying ids.
   * Used mainly to create dummy data for testing.
   * @param table The table to duplicate/take from.
   * @param id The id of entry to duplicate.
   * @param duplicates How many duplicates are desired.
   */
  duplicateEntry(table, id, duplicates): void {
    this.getEntryById(id, table).then(data => {
      for (let i = 0; i < duplicates; i++) {
        data.created_date = new Date();
        this.saveEntry(data, table);
      }
    });
  }

  /**
   * Gets an answer from the database based on the id.
   * @param id The answer id string.
   * @returns The answer from the database.
   */
  getAnswerById(id: string): Promise<Answer> {
    return Promise.resolve(this.answerCollection.doc(id).ref.get().then((doc) => {
      return doc.data();
    }));
  }

  /**
   * Gets a reference to the database and updates it with changes.
   * @param answer The answer object.
   */
  updateAnswer(answer: Answer): void {
    const path =  DB_CONFIG.answer_endpoint + '/' + answer.id;

    this.answerDoc = this.afs.doc<Answer>(path);
    this.answerDoc.update(answer);
  }

  /**
   * Saves an answer and adds it to the answer collection.
   * @param answer The answer object.
   * @returns The added document's id.
   */
  async saveAnswer(answer: Answer): Promise<string> {
    answer.isActive = true;
    answer.created_date = new Date();
    answer.modified_date = new Date();

    try {
      const res = await this.answerCollection.add(answer);
      return res.id;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Removes an answer from the database.
   * @param id The answer id.
   * @returns A promise that the answer has been deleted.
   */
  async deleteAnswer(id: string): Promise<void> {
    try {
      const res = await this.answerCollection.doc(id).delete();
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Iterates through the answer array and removes the answers from the database.
   * @param answer The answers to be deleted
   * @returns An object containing if the method successfully completed and an error message.
   */
  bulkDelete(answer: Answer[]): { success: boolean; errorMessage: string; } {
    let success = true;
    let errorMessage: string;

    answer.forEach((ans: Answer) => {
      this.deleteAnswer(ans.id).then(() => {
        success = true;
      }).catch(error => {
        success = false;
        errorMessage = error.message;
      });
    });

    return { success, errorMessage };
  }

  /**
   * Gets the next set of answers based on the last answer pulled.
   * @returns A set of answers.
   */
  getNextSetOfAnswers(): Observable<Answer[]> {
    // initial set of answers
    // problem is when coming back to view, need to reset last answer
    // could pass flag in, or have reset function
    // need to do testing, probably leave it the way it was for now
    if ( !this.lastAnswer) {
      this.answerCollection = this.afs.collection<Answer>(
        DB_CONFIG.answer_endpoint,
        ref => ref.orderBy('details').limit(this.limit)
      );
    } else {
      this.answerCollection = this.afs.collection<Answer>(
        DB_CONFIG.answer_endpoint,
        ref => ref.orderBy('details').startAfter(this.lastAnswer).limit(this.limit)
      );
    }

    return this.answerCollection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as Answer;

            this.lastAnswer = a.payload.doc;

            // Get document id
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data };
          });
        })
      );
  }

  /**
   * Gets the users from the Firestore.
   * @returns The user collection data.
   */
  getUsers(): Observable<any> {
    this.usersCollection = this.afs.collection<IUser>(
      DB_CONFIG.user_endpoint,
      ref => ref.orderBy('username').limit(this.limit)
    );

    return this.getUserSnapshot();
  }

  /**
   * Gets the user from the Firestore by the subscriber id.
   * @param subscriberId A string of the subscriber id.
   * @returns The user collection data.
   */
  getUserBySubscriber(subscriberId: string): Observable<any> {
    this.usersCollection = this.afs.collection<IUser>(
      DB_CONFIG.user_endpoint,
      ref => ref.where('subscriber_id', '==', subscriberId)
        .orderBy('username').limit(this.limit)
    );

    return this.getUserSnapshot();
  }

  // DOUBLE CHECK THIS DEBUG
  // WAS RETURNING UNDEFINED WITH mappedData const

  /**
   * Gets the user collection data.
   * @returns The data from the AngularFirestoreCollection.
   */
  private getUserSnapshot(): Observable<any> {
    return this.usersCollection.snapshotChanges()
      .pipe(
        map(actions => {
          
          const data = Utilities.mapActions(actions);

          // const mappedData = Utilities.getMappedData(data);
          this.lastUser = data.length > 0 ? data[data.length - 1].lastDoc : this.lastUser;


          return data;
        })
      );
  }

  /**
   * Removes a user from database.
   * @param id The id of the user.
   */
  async deleteUser(id: string) {
    // try putting a flag to say if deleted but dont actually delete
    // needs to allow for creating the account again just new password
    try {
      const res = await this.usersCollection.doc(id).delete();
      return res;
    } catch (error) {
      throw error;
    }
  }


  /**
   * Iterates through the user array and remove them from the database.
   * @param user The users to be deleted. 
   * @returns An object containing if the method successfully completed and an error message.
   */
  bulkDeleteUsers(user: IUser[]): { success: boolean; errorMessage: string; } {
    let success = true;
    let errorMessage: string;

    user.forEach((use: IUser) => {
      this.deleteUser(use.id).then(() => {
        success = true;
      }).catch(error => {
        success = false;
        errorMessage = error.message;
      });
    });

    return { success, errorMessage };
  }

  /**
   * Gets the next set of users based on the last pulled user
   * @returns The users from the database.
   */
  getNextSetOfUsers(): Observable<any> {
    this.usersCollection = this.afs.collection<IUser>(
      DB_CONFIG.user_endpoint,
      ref => ref.orderBy('username').startAfter(this.lastUser).limit(this.limit)
    );

    return this.getUserSnapshot();
  }

  /**
   * Gets the next set of users based on the subscriber.
   * @param subscriberId A string of the subscriber id.
   * @returns The users from the database.
   */
  getNextSetOfUsersBySubscriber(subscriberId: string): Observable<any> {
    this.usersCollection = this.afs.collection<IUser>(
      DB_CONFIG.user_endpoint,
      ref => ref.where('subscriber_id', '==', subscriberId)
        .orderBy('username').startAfter(this.lastUser).limit(this.limit)
    );

    return this.getUserSnapshot();
  }

  /**
   * Gets the user from the database based on the id.
   * @param id The user id.
   */
  getUserById(id: string): Promise<User> {
    try {
      return Promise.resolve(this.usersCollection.doc(id).ref.get().then((doc) => {
        return doc.data();
      }));
    } catch {
      this.navCtrl.navigateForward('braingame/admin/users');
      throw console.error('get user by id failed, go back to user list and come back');
    }
  }

  /**
   * Gets a reference to the database and updates it with changes.
   * @param user The user object.
   */
  // updateUser(user: IUser): void {
  //   const path =  DB_CONFIG.user_endpoint + '/' + user.id;

  //   this.usersDoc = this.afs.doc<IUser>(path);
  //   this.usersDoc.update(user);
  // }

  /**
   * Gets the user by their email.
   * @param email The email in string format.
   * @returns The user from the database.
   */
  getUserByEmail(email: string): Observable<any> {
    const userCollection = this.afs.collection(
      DB_CONFIG.user_endpoint,
      ref => ref.where('email', '==', email)
    );

    return userCollection.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          const mappedData = Utilities.getMappedData(data);

          return mappedData;
        })
      );
  }

  /**
   * Saves the user to the users collection.
   * @param user The user to be saved.
   * @returns The document response id.
   */
  async saveUser(user: IUser): Promise<string> {
    try {
      const res = await this.usersCollection.add(user);
      return res.id;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Gets the leaderboard from the database.
   * @param id The id of the leaderboard.
   * @returns The leaderboard.
   */
  getLeaderboardByEventID(id: string): Observable<IBGLeaderboard[]> {
    const leaderboardCollection = this.afs.collection<IBGLeaderboard>(
      DB_CONFIG.brain_game_leaderboard_endpoint,
      ref => ref.where('event_id', '==', id)
    );

    return leaderboardCollection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as IBGLeaderboard;

            // Get document id
            // tslint:disable-next-line: no-shadowed-variable
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data };
          });
        })
      );
  }

  /**
   * Gets the activities from the database.
   * @param startDate The date to start search from.
   * @param endDate The date to end search at.
   * @param subId A string of the subscriber id.
   * @returns The activities.
   */
  getActivities(startDate: string, endDate: string, subId: string): Observable<any[]> {
    const table = DB_CONFIG.sub_activities_endpoint;
    let activitiesCollection: AngularFirestoreCollection;
    activitiesCollection = this.afs.collection(
      table,
      ref => ref.orderBy('givenDate').startAt(startDate)
      .endAt(endDate).where('subscriber_id', '==', subId)
    );

    return activitiesCollection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data();

            // Get document id
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data } as any;
          });
        })
      );
  }

}
