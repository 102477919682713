import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { DB_CONFIG } from '@app/app.firebase.config';
import { Utilities } from '@common/utilities';
import * as moment from 'moment';
import * as _ from 'underscore';
import { AssessmentService } from './assessment.service';
import { HomeService } from './home.service';
import { User } from '@models/user.interface';
import { AdminService } from './admin.service';
import { BGGame } from '@models/bgGame.model';
import { BrainGameReport } from '@models/bgReport.interface';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { brainGameDefaultConfig, TEST_MODE } from '@app/app.constants.config';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { Assessment } from '@models/assessment.model';

@Injectable({
  providedIn: 'root'
})
export class BrainGameService extends Utilities {

  recommendedLevelsData: any[];
  games: BGGame[];
  private cutOffs = {
    prev3_less_than_value: 8,
    prev5_less_than_value: 14,
    prev3_greater_than_value: 16,
    prev5_greater_than_value: 26
  };

  private brainGameLevelValues = {
    trivia: [
      {
        percentage: '> 79%',
        greater_than_value: 79,
        category: 'Green',
        level: 'Hard',
        value: 3
      },
      {
        percentage: '65%-79%',
        max_value: 79,
        min_value: 65,
        category: 'Yellow',
        level: 'Med',
        value: 2
      },
      {
        percentage: '< 65%',
        less_than_value: 65,
        category: 'Red',
        level: 'Easy',
        value: 1
      }
    ],

    assessment: [
      {
        percentage: '> 79%',
        greater_than_value: 7.9,
        category: 'Green',
        level: 'Hard',
        value: 3
      },
      {
        percentage: '60%-79%',
        max_value: 7.9,
        min_value: 6.0,
        category: 'Yellow',
        level: 'Med',
        value: 2
      },
      {
        percentage: '< 60%',
        less_than_value: 6.0,
        category: 'Red',
        level: 'Easy',
        value: 1
      }
    ],

    lemonade:  [
      {
        score: '> 30',
        greater_than_value: 30,
        category: 'Green',
        value: 1
      },
      {
        score: '20-30',
        max_value: 30,
        min_value: 20,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 20',
        less_than_value: 20,
        category: 'Red',
        value: 3
      }
    ],
    sudoku:  [
      {
        score: '> 30',
        greater_than_value: 30,
        category: 'Green',
        value: 3
      },
      {
        score: '20-30',
        max_value: 30,
        min_value: 20,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 20',
        less_than_value: 20,
        category: 'Red',
        value: 1
      }
    ],
    puzzle_master:  [
      {
        score: '> 20',
        greater_than_value: 20,
        category: 'Green',
        value: 1
      },
      {
        score: '9-20',
        max_value: 20,
        min_value: 9,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 9',
        less_than_value: 9,
        category: 'Red',
        value: 3
      }
    ],
    synapse_scramble:  [
      {
        score: '> 20',
        greater_than_value: 20,
        category: 'Green',
        value: 1
      },
      {
        score: '9-20',
        max_value: 20,
        min_value: 9,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 9',
        less_than_value: 9,
        category: 'Red',
        value: 3
      }
    ],
    brain_defense:  [
      {
        score: '> 20',
        greater_than_value: 20,
        category: 'Green',
        value: 1
      },
      {
        score: '9-20',
        max_value: 20,
        min_value: 9,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 9',
        less_than_value: 9,
        category: 'Red',
        value: 3
      }
    ],
    large_sudoku:  [
      {
        score: '> 100',
        greater_than_value: 100,
        category: 'Green',
        value: 1
      },
      {
        score: '60-100',
        max_value: 100,
        min_value: 60,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 60',
        less_than_value: 60,
        category: 'Red',
        value: 3
      }
    ]
  };

  constructor(
    private adminService: AdminService,
    private assessmentService: AssessmentService,
    private homeService: HomeService,
  ) {
    super();
    this.getGames();
    this.getBrainGameAnalyticsData();
  }

  /**
   * Gets the summary of the games within the date range.
   * @param userId The user id.
   * @returns The summary of the answers.
   */
  getSummaryAnswersByUserWithinDate(userId: string) {
    return this.adminService.getEntryById(userId, DB_CONFIG.bg_responses_endpoint);
  }
  
  /**
  * Gets the Brain Game analytics data.
  */
  async getBrainGameAnalyticsData(userId?: string) {
    let authUser;
    // Use userId when given or use current logged in user
    if(userId) {
      authUser = {id: userId} as any;
    } else {
      authUser = await this.homeService.getAuthUser() as any;
    }

    // Get summary answers from past 3 months
    let responseArr = await this.getSummaryAnswersByUserWithinDate(authUser.id)
    if(!responseArr){
      responseArr = {responses: []};
    }
    const oldestDate = moment().subtract(3, 'months');

    responseArr.responses = responseArr.responses.filter((response: any) => {
      const relevantDate = moment(response.created_date);
      return (oldestDate.isBefore(relevantDate));
    })

    // Get Latest Assessment to Help Determine Level
    const assessmentData =  await this.assessmentService.getAssessments(authUser.id)
    const report = await this.assessmentService.calculateReport(assessmentData)
    // Get Recommended Levels
    this.recommendedLevelsData = this.filterSummaryAnswersByGameType(responseArr.responses, report[report.length - 1]);
    return this.recommendedLevelsData;
  }

  /**
   * Gets the games from the AdminService.
   */
  async getGames() {
    if(this.games){
      return this.games;
    } else {
      this.adminService.getEntries(DB_CONFIG.games_endpoint, 'id').pipe(take(1)).subscribe(games => {
        this.games = games;
      });
      for(let i = 0; i < 5; i++){
        if(this.games){
          return this.games;
        }
        await this.sleep(1000);
      }
      throw new Error('Games did not load in time')
    }

    
  }



    
  /**
   * Filter Summary Answers By Game Type
   * @param data The Game Dara
   * @param assessment The Assessment
   */
   public filterSummaryAnswersByGameType(data: BrainGameReport[], assessment: any) {
    data = data.reverse();
    const recommendationLevels = [];
    // Set All Game Types
    const puzzleGameTypes  = Object.values(PuzzleGameEnum);
    // Apply Recommended Logic to Each Game Type
    puzzleGameTypes.forEach((gameType) => {
      let recommendedLevel = '';

      const testSort = this.getLatestGameResults(data, gameType); // Get Previous 5 Games Played
      // Check if there's at least 3 games data.
      if (testSort) {
        recommendedLevel = this.getRecommendedLevel(testSort, gameType); // Get Recommended Level
        recommendationLevels.push({difficulty: recommendedLevel, game: gameType});
      } else {
        let gameName;
        // Assessment Logic Here
        // Check if assessment score is available
        if (assessment !== undefined) {
          const triviaStrategyValue = this.assignStrategyValue(assessment.overall, 'assessment');
          gameName = this.toTitleCase(triviaStrategyValue.level);
        } else {
          gameName = GameDifficultyEnum.Easy;
        }
        recommendationLevels.push({difficulty: gameName, game: gameType});
      }
    });
    
    // Get Recommended Level for All Games
    return recommendationLevels;
  }

  /**
   * returns 3-5 most recent games based on first 3 games of a difficulty found
   * @param data Game Data
   * @param gameName Game Name
   */
  public getLatestGameResults(data: BrainGameReport[], gameName: PuzzleGameEnum ) {

    
    const selectedGameTypeData = _.filter(data, (item) => {
      return item.game_name === gameName;
    });
    // Game data
    let testSort;

    // 0 is easy, 1 is medium, 2 is hard. index
    const gamesArr = [[], [], []];
    // Variable to keep track of which difficulty is selected to add to gameArr
    let sortedDiff = 3;

    for ( let game of selectedGameTypeData) {
      const round = game as BrainGameReport;
      // sort by difficulty
      if ( round.difficulty === GameDifficultyEnum.Hard) {
        if (sortedDiff === 3 || sortedDiff === 2) {
          gamesArr[2].push(round);
          // once it reaches 3 games, it locks in that difficulty as the measuring stick
          if (gamesArr[2].length === 3) {
            sortedDiff = 2;
          } else if (gamesArr[2].length === 5) {
            break;
          }
        }
      } else if ( round.difficulty === GameDifficultyEnum.Medium) {
        if (sortedDiff === 3 || sortedDiff === 1) {
          gamesArr[1].push(round);
          if (gamesArr[1].length === 3) {
            sortedDiff = 1;
          } else if (gamesArr[1].length === 5) {
            break;
          }
        }
      } else {
        if (sortedDiff === 3 || sortedDiff === 0) {
          gamesArr[0].push(round);
          if (gamesArr[0].length === 3) {
            sortedDiff = 0;
          } else if (gamesArr[0].length === 5) {
            break;
          }
        }
      }
    }
    if (sortedDiff === 3) {
      return null;
    }
    
    testSort = {data: gamesArr[sortedDiff], difficulty: sortedDiff } as any;
    
    return testSort;
  }


  /**
   *
   * @param gameData The Game Data
   * @param currentLevel The Current Level
   */
  public getRecommendedLevel(gameData: any, currentLevel: string) {
    let recommendedLevel = '';
    const easy = 'easy', medium = 'medium', hard = 'hard';
    const gameDataValues = gameData.data;

    let i = 0, total3, total5;
    let triviaSum3 = 0, triviaSum5 = 0;
    let strategySum3 = 0, strategySum5 = 0;
    let strategyName = currentLevel.toLowerCase(), strategyLevel, levelUp, levelDown;
    const diff = gameData.difficulty;

    // Determine Level Up and Down states
    if (diff === 0) {
      levelUp = medium;
      levelDown = easy;
      strategyLevel = easy;
    } else if (diff === 1) {
      levelUp = hard;
      levelDown = easy;
      strategyLevel = medium;
    } else if (diff === 2) {
      levelUp = hard;
      levelDown = medium;
      strategyLevel = hard;
    }
    
    gameDataValues.forEach((gameValue: any) => {
      const maxScore = brainGameDefaultConfig.number_of_rounds * 4 * 3;
      const triviaPercentage =  this.calculatPercentage(gameValue.trivia_score, Number(maxScore.toFixed(0)));
      const triviaStrategyValue = this.assignStrategyValue(triviaPercentage, 'trivia');
      const gameStrategyValue = this.assignStrategyValue(gameValue.game_score, strategyName);

      // Add Strategy Values
      if (i < 3) { // Previous 3
        triviaSum3 += triviaStrategyValue.value;
        strategySum3 += gameStrategyValue.value;
      }
      if (i < 5) {  // Previous 5
        triviaSum5 += triviaStrategyValue.value;
        strategySum5 += gameStrategyValue.value;
      }
      
      i++;

    });
    
    total3 = triviaSum3 + strategySum3;
    total5 = triviaSum5 + strategySum5;

    
    // Recommended Level Value Check
    // Only check for total5 if there are 5 games
    if((total3 >= this.cutOffs.prev3_greater_than_value) || 
       ((i == 5) && total5 >= this.cutOffs.prev5_greater_than_value) ) {
      // Level Up
      recommendedLevel = this.toTitleCase(levelUp);
    } else if ((total3 <= this.cutOffs.prev3_less_than_value) ||
        ((i == 5) && total5 <= this.cutOffs.prev5_less_than_value) ) {
      // Level Down
      recommendedLevel = this.toTitleCase(levelDown);
    } else {
      // Stay the same
      recommendedLevel = this.toTitleCase(strategyLevel);
    }
    
    return recommendedLevel;
  }

  /**
   * Assigned Strategy Value Dynamically
   * @param gameScore The gameScore
   * @param stratery The Stategy/Game Name
   */
  public assignStrategyValue(gameScore: number, stratery: string) {
    const stategyValue = {
      category: '',
      level: '',
      value: 0
    };
    
    const levelValues = this.brainGameLevelValues[stratery];

    // Greater than the Highest
    if ( gameScore >= levelValues[0].greater_than_value) {
      stategyValue.value = levelValues[0].value;
      stategyValue.level = GameDifficultyEnum.Hard;
      stategyValue.category = levelValues[0].category;
    } else if ( (gameScore <= levelValues[1].max_value) && ( gameScore >= levelValues[1].min_value)) { // In between Medium Values
      stategyValue.value = levelValues[1].value;
      stategyValue.level = GameDifficultyEnum.Medium;
      stategyValue.category = levelValues[1].category;
    } else if ( (gameScore < levelValues[2].less_than_value)) {
      stategyValue.value = levelValues[2].value;
      stategyValue.level = GameDifficultyEnum.Easy;
      stategyValue.category = levelValues[2].category;
    }
    return stategyValue;
  }

  /**
   * Calculate Percentage Score
   * @param score The score user got
   * @param maxScore The score mark
   */
  public calculatPercentage(score: number, maxScore: number) {
     return ((score / maxScore ) * 100);
  }
}
