import { Injectable } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AdminService } from './admin.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import _ from 'underscore';
import { Utilities } from '@common/utilities';
import { AppEnum } from '@enums/AppEnum';
import { APPS } from '@app/app.constants.config';

@Injectable({
  providedIn: 'root'
})
export class ProgressService extends Utilities {

  daysBack = new BehaviorSubject(null);
  weekDates = new BehaviorSubject<{start, end}>(null);
  sessions = [];
  user;
  compliance = new BehaviorSubject(0);
  overallTimer = new BehaviorSubject(0);

  dailyInitial = true;

  private suggestedUse = {
    yahootie: 3,
    braingame: 5,
    clubhouse: 1,
    happyplace: 5,
  };

  private goalPercentage = {
    yahootie: 43,
    braingame: 71,
    clubhouse: 14,
    happyplace: 71,
  };

  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService
  ) { super();
  this.init(); }

  init() {
    this.getUser();
  }

  getUser() {
    this.authService.userSubject.subscribe(user => {
      this.user = user;
      if(!user){return}
      this.onceUserProcessed();
    })
  }

  async checkIfBeforeToday(arr: any[]) {
    // filter by time
    const times = arr.map(data => {
      if(!data.created_date || Object.keys(data.created_date).length === 0){return;}
      const today = moment(this.daysBack.value).startOf('d');
      const response_time = moment(data.created_date);
      const diff = today.isSame(response_time, 'day');
      if(diff) {
        return data;
      }
    });
    await Promise.all(times);
    const responses = times.filter(n => n);
    return responses;
  }

  async checkIfInWeek(arr: any[]) {
    if(!arr){return []}
    // filter by time
    const times = arr.map(data => {
      if(!data.created_date || Object.keys(data.created_date).length === 0){return;}
      const response_time = moment(data.created_date);
      if(response_time.isAfter(this.weekDates.value.start) && response_time.isBefore(this.weekDates.value.end)) {
        return data;
      } else {
        return;
      }
    });
    await Promise.all(times);
    const responses = times.filter(n => n);
    return responses;
  }

  async changeWeekDates(weeksBack){
    if(!weeksBack.start || !weeksBack.end){throw new Error('weeks back is in the wrong format')}
    this.overallTimer.next(0);
    const start = weeksBack.start.startOf('d');
    const end   = weeksBack.end.endOf('d');
    this.weekDates.next({start, end});
    const apps = await this.getCompliance(start, end);
    this.compliance.next(await this.complianceFromApps(apps));
    return;
  }

  onceUserProcessed() {
    const start = moment().day(0).subtract(6, 'w').startOf('d').toDate();
    const end = moment().endOf('d').toDate();
    this.getSessions(start, end);
  }

  getSessions(start, end) {
    if(!this.user){throw new Error('no user')}
    this.adminService.getEntriesByDate(DB_CONFIG.overall_session_endpoint, start, end, 'user_id', this.user.id, 'start_date').subscribe(sessions => {
      this.sessions = sessions;
    });
  }

  async getSessionsByDay(day){
    const sess = this.sessions.filter(session => {return this.isBetweenDay(session.start_date, day)});
    return sess;
  }

  isBetweenDay(obj, date) {
    const testTime = this.firebaseDateToDate(obj);
    var objectStart = moment(testTime);
    var start = moment(date);
    return (objectStart.isSame(start, 'd'));
  }

  async getAverageCompliance(){
    const start = moment().day(0).subtract(6, 'w').startOf('d');
    const end = moment().day(-1).endOf('d');
    const apps = await this.getCompliance(start, end);
    return this.complianceFromApps(apps);
  }

  async getCompliance(start, end) {
    const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    const loadTimer = 500; const loadTries = 5; let tries = 0;
    while(this.sessions.length === 0) {
      await wait(loadTimer).then( () => {
        console.log('session load try #' + tries);
        tries++;
        if (tries > loadTries) {
          throw new Error('Sessions not loading');
          // console.error('sessions not loading')
        }
      });
    }
    const sess = this.sessions.filter(session => {return this.isBetweenDates(session.start_date, start, end)});
    const daysBack = Math.ceil(end.diff(start, 'd', true))
    return this.calculateCompliance(sess, daysBack);
  }


  isBetweenDates(obj, startDate, endDate) {
    const testTime = this.firebaseDateToDate(obj);
    var objectStart = moment(testTime);
    var start = moment(startDate);
    var end = moment(endDate);
    return (objectStart.isSameOrAfter(start) && objectStart.isSameOrBefore(end));
  }

  /**
   * Calculate Compliance
   * @param app App Data
   */
  async calculateCompliance(sessions, daysBack) {
    if(!daysBack){daysBack = 1}
    const grouped_sessions = _.groupBy(sessions, item => {return item.app_id});
    const apps = APPS;
    const promises = apps.map(appie => {
      const app = Object.assign(new Object, appie);
      if(app.name === 'Assessment'){return app;}
      const appName = app.name.replace(/\s+/g, '').toLowerCase();
      const suggested = (this.suggestedUse[appName]/7)*daysBack; // calculates suggested use based on how many days we are calculating from
      let game_sessions = grouped_sessions[app.name];
      // Remove duplicate games played on the same day
      game_sessions = _.uniq(game_sessions, (item, key) => {
        return moment(this.convertTimestampToDate(item.start_date)).format('LL');
      });
      if(!game_sessions){game_sessions = []}
      app['numberOfSessionsPlayed'] = game_sessions.length;
      app['goalPercentage'] = this.goalPercentage[appName];
      app['compliancePercentage'] = (1 - ((suggested - game_sessions.length) / suggested )) * 100;
      if(app['compliancePercentage'] > 100){app['compliancePercentage'] = 100;}
      if(game_sessions.length){
        const givenTime = moment(this.convertTimestampToDate(game_sessions[0].start_date))
        const timeDiff = moment().add(1,'d').startOf('date').diff(givenTime, 'days')
        app['daysSinceLastUse'] = timeDiff;
      }
      return app;
    });
    await Promise.all(promises);
    return promises;
  }

  /**
   * Calculate Compliance
   * @param app App Data
   */
  async calculateComplianceSortedSessions(sessions, daysBack) {
    if(!daysBack){daysBack = 1}
    const grouped_sessions = sessions;
    const apps = APPS;
    const promises = apps.map(appie => {
      const app = Object.assign(new Object, appie);
      if(app.name === 'Assessment'){return app;}
      const appName = app.name.replace(/\s+/g, '').toLowerCase();
      const suggested = (this.suggestedUse[appName]/7)*daysBack; // calculates suggested use based on how many days we are calculating from
      let game_sessions = grouped_sessions[app.name];
      // Remove duplicate games played on the same day
      game_sessions = _.uniq(game_sessions, (item, key) => {
        return moment(this.convertTimestampToDate(item.start_date)).format('LL');
      });
      if(!game_sessions){game_sessions = []}
      app['numberOfSessionsPlayed'] = game_sessions.length;
      app['goalPercentage'] = this.goalPercentage[appName];
      app['compliancePercentage'] = (1 - ((suggested - game_sessions.length) / suggested )) * 100;
      if(app['compliancePercentage'] > 100){app['compliancePercentage'] = 100;}
      if(game_sessions.length){
        const givenTime = moment(this.convertTimestampToDate(game_sessions[0].start_date))
        const timeDiff = moment().add(1,'d').startOf('date').diff(givenTime, 'days')
        app['daysSinceLastUse'] = timeDiff;
      }
      return app;
    });
    await Promise.all(promises);
    return promises;
  }

  async complianceFromApps(apps) {
    let compliance = 0;
    let len = 0;
    const promises = apps.map(app => {
      if(app.name === 'Assessment'){return}
      compliance += app['compliancePercentage'];
      len++
    });
    await Promise.all(promises);
    return compliance/len;
  }

  async assignCategoryFromApps(apps){
    const promises = apps.map(async(app) => {
      if (app.name !== AppEnum.Assessment) {
        // if the app hasn't met compliance percentage, and the app hasn't been played today, add it today's activities
        if (app.compliancePercentage < app.goalPercentage && app.daysSinceLastUse >= 1 ) {
          app['category'] = 'today';
        // if the app has been played today, add to more activities
        } else if(app.daysSinceLastUse === 0) {
          app['category'] = 'today-compliant';
        }else {
          app['category'] = 'more';
        }            
      }
    });
    await Promise.all(promises);
  }
}
