import { Injectable } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { Meeting } from '@models/meeting.model';
import { Presentation } from '@models/presentation.model';
import { User } from '@models/user.interface';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AdminService } from './admin.service';
import { SubscriberService } from './subscriber.service';

@Injectable({
  providedIn: 'root'
})
export class ClubhouseService {

  meeting = {
    attendees: [],
    presentation_id: '',
    presentation_name: '',
    subscriber_id: '',
    online: false,
    location_url: '',
    start_date: new Date().toJSON(),
    is_active: true,
    id: '',
  } as Meeting|any;
  meetingObj = {meetings: [] as Meeting[], id: '',}

  isZoom: boolean;
  orientationChange = new BehaviorSubject<boolean>(false);

  topics = [] as Presentation[];
  subscriber;
  sub_meetings = [] as Meeting[];
  meetingsLoaded = new Subject();
  live_meeting_loaded = new Subject();

  presentedMeeting = {} as Meeting;
  liveMeeting = null as Meeting;

  tracking_day = {
    presentations_reviewed: [],
    meetings_attended: []
  }

  tracking_overall = {
    tracking_data: [
      {
        presentations_reviewed: [],
        meetings_attended: []
      }
    ]
  }

  constructor(
    private adminService: AdminService,
    private subService: SubscriberService
  ) {
    this.init();
   }

   init() {
    this.getPresentations();
    this.subService.subscriber.subscribe(val => {
      if(!val){
        return;
      }
      this.subscriber = val.id;
      this.meeting = val.id;
      this.getMeetingBySubscriberId();
    })
   }

  /**
   * Gets the presentations from database and stores the topics.
   */
  getPresentations(): void {
    this.adminService.getEntries(DB_CONFIG.presentation_endpoint, 'name')
    .pipe(take(1))
    .subscribe(pressies => {
      this.topics = pressies;
    });
  }

  /**
   * Finds the presentation based on either a name or an id, prioritizing name.
   * @param name The name of presentation you're searching for (optional).
   * @param id The id of presentation you're searching for (optional).
   * @returns The presentation.
   */
  findPresentation(name?: string, id?: string): Presentation {
    if(name) {
      return this.topics.find(topic => {return topic.name === name});
    } else if (id) {
      return this.topics.find(topic => {return topic.id === id});
    }
  }

  /**
   * Gets the 100 most recent, or future, meetings for the subscriber id.
   */
  async getMeetingBySubscriberId() {
    if(this.sub_meetings.length !== 0){
      return this.sub_meetings;
    }
    let meetingObj = await this.adminService.getEntryById(this.subscriber, DB_CONFIG.meeting_endpoint)
    if(!meetingObj){
      meetingObj = {meetings: [] as Meeting[], id: this.subscriber};
    }
    this.meetingObj = meetingObj;
    this.sub_meetings = meetingObj.meetings;
    this.meetingsLoaded.next(true);
    this.checkForLiveMeeting();
    return meetingObj.meetings;
  }

  /**
   * Checks if a meeting is live.
   * @throws An error if there is no meeting.
   */
  checkForLiveMeeting(): void {
    const liveIndex = this.sub_meetings.findIndex((meeting) => {return meeting.is_active === true});
    if(liveIndex === -1) {
      console.log('no meetings found');
      return;
    }
    this.liveMeeting = this.sub_meetings[liveIndex];
    const before = moment().diff(this.liveMeeting.start_date, 'day') > 0;
    
    // means meeting has already passed by a day
    if(before) {
      this.liveMeeting.is_active = false;
      this.updateMeeting(this.liveMeeting);
    } else {
      this.live_meeting_loaded.next(true);
    }
  }

  /**
   * Starts a meeting.
   * @param meeting The meeting to be started.
   * @throws An error if there is no live meeting.
   */
  startMeeting(meeting: Meeting): void {
    const sub_meetings = this.meetingObj.meetings;
    const liveIndex = sub_meetings.findIndex(meet => {return meet.start_date === meeting.start_date});
    if(liveIndex === -1) {
      throw new Error('Assertion Error. meeting should always be part of this list, should never reach this.');
    }
    this.presentedMeeting = meeting;
    this.presentedMeeting.is_active = true;
    this.meetingObj.meetings[liveIndex] = meeting;
    this.updateMeeting();
  }

  /**
   * Adds attendees to the presented meeting.
   * @param users The users to be added.
   * @throws An error if there is no presented meeting.
   */
  addAttendees(users: User[]): void {
    if(!this.presentedMeeting) {
      throw new Error('Assertion Error. presented meeting must be there to start a meeting, should never reach this.');
    }
    users.map(user => {
      this.presentedMeeting.attendees.push(user.id)
    })
  }

  /**
   * Ends the presented meeting.
   * @throws An error if the presented meeting is not in the sub meetings array.
   */
  endMeeting(): void {
    const sub_meetings = this.meetingObj.meetings;
    const meetingIndex = sub_meetings.findIndex(meeting => {return meeting.start_date === this.presentedMeeting.start_date});
    if(meetingIndex === -1) {
      throw new Error('end meeting failed. unable to find meeting among subscriber');
    }
    const meeting = sub_meetings[meetingIndex];
    meeting.is_active = false;
    this.meetingObj.meetings[meetingIndex] = meeting;
    this.presentedMeeting = null;
    this.updateMeeting();
  }

  saveMeeting(newMeeting) {
    if(newMeeting.presentation_id){
      newMeeting.presentation_id = this.findPresentation(newMeeting.presentation_name).id;
    }
    this.meetingObj.meetings.push(newMeeting);
    return this.adminService.saveEntryById(this.meetingObj, DB_CONFIG.meeting_endpoint);
  }

  signUpForMeeting(meeting, user){
    if(!user || !user.id){
      return;
    }
    meeting.attendees.push(user.id);
    this.adminService.saveEntryById(this.meetingObj, DB_CONFIG.meeting_endpoint);
    return true;
  }

  updateMeeting(newMeeting?) {
    if(newMeeting){
      const sub_meetings = this.meetingObj.meetings;
      const meetingIndex = sub_meetings.findIndex(meeting => {return meeting.id === newMeeting.id});
      this.meetingObj.meetings[meetingIndex] = newMeeting;
    } 
    this.adminService.updateEntry(this.meetingObj, DB_CONFIG.meeting_endpoint).then(data => {
      this.sub_meetings = this.meetingObj.meetings;
    });
  }

  deleteMeeting(delete_meeting){
    const sub_meetings = this.meetingObj.meetings;
    const meetingIndex = sub_meetings.findIndex(meeting => {return meeting.id === delete_meeting.id});
    this.meetingObj.meetings.splice(meetingIndex, 1);

    this.adminService.updateEntry(this.meetingObj, DB_CONFIG.meeting_endpoint).then(data => {
      this.sub_meetings = this.meetingObj.meetings;
    });
  }

  presentation_reviewed(pres) {
    this.tracking_day.presentations_reviewed.push({'pres_name': pres.presentation_name});
    const len = this.tracking_overall.tracking_data.length;
    this.tracking_overall.tracking_data[len-1] = this.tracking_day;
    this.adminService.saveEntryByUserId(this.tracking_overall, DB_CONFIG.clubhouse_tracking_endpoint);
  }

}
