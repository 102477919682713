import { Injectable } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { SubscriberModel } from '@models/subscriber.model';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';
import { RoleEnum } from '@enums/RoleEnum';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {
  subscriberIdSubject = new BehaviorSubject<string>(null);
  subscriber = new BehaviorSubject<SubscriberModel>(null);
  subUsers;

  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService
  ) {
    this.authService.userSubject.subscribe(user => {
      if(!user){return}
      if (user.role === RoleEnum.Admin){
        this.getSubscriberById('5e9a3bf6-87dd-428f-8ac7-5389dd74188d');
      } else if(user.subscriber_id){
        this.getSubscriberById(user.subscriber_id);
      } else {
        throw new Error('No Subscriber Id on auth user')
      }
    })
  }

  getSubscriberById(id: string) {
    this.adminService.getEntryById(id, DB_CONFIG.subscriber_endpoint).then(sub => {
      if(sub) {
        this.subscriber.next(sub);
        this.getUsersBySubIdPRIVATE(sub.id);
      } else {
        throw new Error('No Subscriber found with the given id: "' + id + '"')
      }
    });
  }

  /**
   * also can access subUsers with no id passed
   * @param id 
   * @returns a promise with users from sub id
   */
  private getUsersBySubIdPRIVATE(id?) {
    if(!id){
      return this.subUsers;
    }
    this.adminService.getEntries(DB_CONFIG.user_endpoint, 'subscriber_id', 'subscriber_id', id).subscribe(users => {
      this.subUsers = users;
      return id;
    })
    
  }

  /**
   * 
   * @param id 
   * @returns a promise with users from sub id
   */
  getUsersBySubId(id) {
    return this.adminService.getEntries(DB_CONFIG.user_endpoint, 'subscriber_id', 'subscriber_id', id);
    
  }
}
