<div class="brain-body">

  <div>
    <!-- Whenever a user takes an assessment they first must get setup -->
    <div *ngIf="!setup_done" style="padding: 10px 30px">
      <app-setup></app-setup>
    </div>

    <!-- Different experience for first time users? -->


    <!-- Quizzes Wrapper + progress bar -->
    <div *ngIf="setup_done" style="padding: 10px 30px">
      <!-- Progress Bar -->
      <div >
        <div class="row">
          <div class="col-4 progress-container">
            <h2>Quiz 1</h2>
            <i *ngIf="current_quiz > 1" class="fa-solid fa-check checkmark"></i>
          </div>
          <div class="col-4 progress-container">
            <h2>Quiz 2</h2>
            <i *ngIf="current_quiz > 2" class="fa-solid fa-check checkmark"></i>
          </div>
          <div class="col-4 progress-container">
            <h2>Quiz 3</h2>
            <i *ngIf="current_quiz > 3" class="fa-solid fa-check checkmark"></i>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4 progress-container">
            <ion-progress-bar *ngIf="current_quiz !== 1" class="finished-progress" value='1'></ion-progress-bar>
            <ion-progress-bar *ngIf="current_quiz === 1" [value]='current_progress'></ion-progress-bar>
          </div>
          <div class="col-4 progress-container">
            <ion-progress-bar *ngIf="current_quiz !== 2" class="finished-progress" value='1'></ion-progress-bar>
            <ion-progress-bar *ngIf="current_quiz === 2" [value]='current_progress'></ion-progress-bar>
          </div>
          <div class="col-4 progress-container">
            <ion-progress-bar *ngIf="current_quiz !== 3" class="finished-progress" value='1'></ion-progress-bar>
            <ion-progress-bar *ngIf="current_quiz === 3" [value]='current_progress'></ion-progress-bar>
          </div>
        </div>
      
      </div>

      <!-- Quizzes(1-3) -->
      <div>
        <app-quizone *ngIf="current_quiz === 1"></app-quizone>

        <app-quiztwo *ngIf="current_quiz === 2"></app-quiztwo>

        <app-quizthree *ngIf="current_quiz >= 3"></app-quizthree>
      </div>

    </div>



  </div>

  <app-footer></app-footer>
</div>

