import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { LeaderboardService } from '@services/leaderboard.service';
import { IBGLeaderboardEntry } from '@interface/bgleaderentry.interface';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { BrainGameReport } from '@models/bgReport.interface';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { ModalController, NavController } from '@ionic/angular';
import moment from 'moment';
import { ILeaderBoard } from '@interfaceleaderboard.interface';
import { GameService } from '@services/game.service';
import { Utilities } from '@common/utilities';
import { NavigationEnum } from '@enums/NavigationEnum';
import { DataTrackingService } from '@services/data-tracking.service';
import { AppEnum } from '@enums/AppEnum';
import { HomeService } from '@services/home.service';
import { SubscriberService } from '@services/subscriber.service';
import {first} from 'rxjs/operators'

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent extends Utilities implements OnInit {
  @Input() isModal? = false;
  @Input() yahootieLeaderboard?: ILeaderBoard[];
  @Input() game;
  @Input() difficulty;
  @Input() subscriber_id;
  @Input() update;

  teamSummary: IBGLeaderboardEntry[];
  teamSummaryDisplay: any[];
  currentGame: PuzzleGameEnum;
  currentSummary: BrainGameReport;
  currentIndex: number = this.bgController.leaderIndex;
  currentDate: string;
  currentScore: number;
  showCurrent = true;
  anon: boolean = false;

  constructor(
    private bgController: BrainGameControllerService,
    private leaderService: LeaderboardService,
    public navCtrl: NavController,
    private gameService: GameService,
    private dataService: DataTrackingService,
    private homeService: HomeService,
    private subService: SubscriberService
  ) { super(); }

  ngOnInit() {
    if(this.yahootieLeaderboard){
      // yahootie leaderboard
      this.setYahootieLeaderboard()
    }
    else if(this.game && this.difficulty){
      // get board from service or something
      this.getBoardManually();
    } else {
      // get currently loaded board, likely from playing brain game
      this.setBrainGameLeaderboard()
    }
  }

  ngOnChanges() {
    if(this.yahootieLeaderboard){
      // yahootie leaderboard
      this.setYahootieLeaderboard()
    }
    else if(this.game && this.difficulty){
      // get board from service or something
      this.getBoardManually();
    } else {
      // get currently loaded board, likely from playing brain game
      this.setBrainGameLeaderboard()
    }
  }

  async getBoardManually() {
    // if subscriber is not passed, get users sub id first
    if(!this.subscriber_id || this.subscriber_id === ''){
      this.subService.subscriber.subscribe(async (sub) => {
        this.subscriber_id = sub.id;
        const board = await this.leaderService.getLeaderboard2(this.game, this.difficulty, sub.id) as any;
        this.teamSummaryDisplay = board.board;
        this.teamSummaryDisplay.forEach((leader) => {
          leader.display_date = moment(leader.created_date).format('MM/DD');
          leader.overall_score = leader.overall_score;
        });
      })

      // if it is
    } else {
      try{
        const board = await this.leaderService.getLeaderboard2(this.game, this.difficulty, this.subscriber_id) as any;
        this.teamSummaryDisplay = board.board;
        this.teamSummaryDisplay.forEach((leader) => {
          leader.display_date = moment(leader.created_date).format('MM/DD');
          leader.overall_score = leader.overall_score;
        });
      } catch{
        this.teamSummaryDisplay = null;
      }
      
    }
  }

  /**
   * Sets the leaderboard for the Brain Game players.
   */
  async setBrainGameLeaderboard() {
    // get the summary based on event id
    this.currentSummary = this.bgController.response;
    this.currentDate = moment(this.currentSummary.created_date).format('MM/DD');
    this.currentScore = this.currentSummary.overall_score;
    this.currentIndex = this.bgController.leaderIndex - 1;

    // get the finished leaderboard from the service
    this.teamSummary = this.leaderService.getDisplay();

    this.teamSummaryDisplay = JSON.parse(JSON.stringify(this.teamSummary));
    const promises = this.teamSummaryDisplay.map((leader, index) => {
      if(!leader) {delete this.teamSummaryDisplay[index]; return;}
      leader.display_date = moment(leader.created_date).format('MM/DD');
      leader.overall_score = leader.overall_score;
    });
    await this.sleep(1000)
    
    const boldGuy = document.getElementsByClassName('boldguy')[0];
    boldGuy.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }

  /**
   * Sets the leaderboard for the Yahootie players.
   */
  async setYahootieLeaderboard() {
    this.gameService.yahootieSubject.subscribe(yahootieSubject => {
      this.currentScore = yahootieSubject.overall_score;
    });

    this.currentDate = moment().format('MM/DD');
    this.currentIndex = this.gameService.leaderIndex - 1;
    
    // get the finished leaderboard from the service
    this.teamSummary = this.leaderService.getDisplay();

    this.teamSummaryDisplay = JSON.parse(JSON.stringify(this.teamSummary));
    const promises = this.teamSummaryDisplay.map((leader, index) => {
      if(!leader) {delete this.teamSummaryDisplay[index]; return;}
      leader.display_date = moment(leader.created_date).format('MM/DD');
      leader.overall_score = leader.overall_score;
    });
    await this.sleep(1000)
    
    const boldGuy = document.getElementsByClassName('boldguy')[0];
    boldGuy.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }

  /**
   * Navigates to the Yahootie's game configuration page for Yahootie 
   * or to the trivia page for Brain Game.
   */
  playAgain(): void {
    this.endOfPathProcessing();
    if (this.yahootieLeaderboard) {
      this.navigateToPath('yahootie/game-configuration', NavigationEnum.Back);
    }
    else {
      this.dataService.createAppObject(AppEnum.BrainGame);
      this.bgController.navAway('trivia');
    }
    this.closeCurrentModal();
  }

  /**
   * Navigates the user back to the training page.
   */
  done(): void {
    // this.endOfPathProcessing();

    // //Closing yahootie modal.
    // if (this.isModal && this.yahootieLeaderboard) {
    //   this.closeCurrentModal();
    // }

    // //Checking if all activities have been completed.
    // if(this.homeService.todaysActivities.length === 0) {
    //   this.navCtrl.navigateForward('training');
    //   // this.navCtrl.navigateForward('highlights');
    // } else {
    //   this.navCtrl.navigateBack('apps-menu');
    // }

    // this.navCtrl.navigateBack('apps-menu');
    this.closeCurrentModal();
    
  }

  doneModal() {
    this.closeCurrentModal();
  }

  endOfPathProcessing() {
    // Yahootie End of Path
    if(this.yahootieLeaderboard) {
      this.gameService.resetGameConfigs();
      this.gameService.endOfPath(this.gameService.yahootieResults.overall_score, this.gameService.leaderIndex);
    // Brain Game End of Path; handled by bgController gamemaster
    } else {
      this.bgController.endOfPath();
    }
  }

  /**
   * Toggles the user's name between Anonymous and their name
   * Saving the current score as Anonymous to leaderboard.
   */
  anonUser(): void {
    const name = this.anon ? this.currentSummary.user_name : "Anonymous";

    //Checking if yahootie leaderboard modal is displayed.
    if (this.isModal && this.yahootieLeaderboard) {
      //The index of yahootie current score.
      const yahootieLeaderIndex = this.gameService.leaderIndex - 1;
      this.yahootieLeaderboard[yahootieLeaderIndex]['user_name'] = name;
      this.leaderService['leaderboard'].board[yahootieLeaderIndex].user_name = name;
    }
    else {
      //The index of current score.
      const leaderIndex = this.bgController.response['leaderboard'] - 1;
      this.leaderService['leaderboard'].board[leaderIndex].user_name = name;
      this.teamSummary[this.currentIndex].user_name = name;
    }
    
    this.leaderService.updateLeaderboard();
    this.teamSummaryDisplay[this.currentIndex].user_name = name;

    this.anon = !this.anon;
  }

  /**
   * Closes the modal if the leaderboard if showing the Yahootie players.
   */
  closeCurrentModal() {
    this.closeModal();
    if (this.yahootieLeaderboard) {
      this.closeModal();
    }
    else {
      this.bgController.closeLeaderboardModal.next(true);
    }
  }
}
