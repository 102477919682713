import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { OutsideTraining } from '@interface/outside_training.interface';
import { OutsideTrainingService } from '@services/outside-training.service';

@Component({
  selector: 'app-outside-training-flyout',
  templateUrl: './outside-training-flyout.component.html',
  styleUrls: ['./outside-training-flyout.component.scss'],
})
export class OutsideTrainingFlyoutComponent extends Utilities implements OnInit {

  @Input() trainingCount: number;
  @Input() outsideTrainings: OutsideTraining[] = [];
  @Input() trainingText: string;
  
  categorySettings = this.trainingService.categorySettings;

  constructor(
    private trainingService: OutsideTrainingService
  ) { super(); }

  ngOnInit() {
    this.clearList();
    this.setCategoryList();
  }

  /**
   * Sets the lists of the category settings with the outside training that is within that category,
   * if the list length is less than the number of outside trainings an undefined placeholder is added
   */
  setCategoryList(): void {
    this.outsideTrainings.forEach(training => {
      training.categories.forEach((category, i) => {
        if (category.selected) {
          this.categorySettings[i].list.push(training.name);
        }
      })
    });

    this.categorySettings.forEach(setting => {
      const originalLength = setting.list.length; 
      for (let i = 0; i < this.trainingCount - originalLength; i++) {
        setting.list.push(undefined);
      }
    });
  }

  /**
   * Clears the list of each category setting so setCategoryList() can function properly
   */
  clearList(): void {
    this.categorySettings.forEach(setting => {
      setting.list = [];
    });
  }
}
